import { Link, useLocation } from 'react-router-dom';
import { SessionManager } from '../../../utils/session-manager';
import {
  Content,
  ContentColumn,
  ContentPrice,
  ContentWrapper,
  HeaderWrapper,
  Price,
  PricePrefix,
  Title,
  WrapperPrice,
  PortugalTitle
} from './headerStyles';
import { useTranslation } from 'react-i18next';

export const Header = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const showPortugal =
    new URLSearchParams(location.search).get('utm_source') === 'oca-pt';

  return (
    <HeaderWrapper>
      <ContentWrapper>
        <Content>
          {showPortugal && (
            <PortugalTitle>Vai a estudar para o estrangeiro?</PortugalTitle>
          )}
          <Title>{t('landing.header.title')}</Title>
          <ContentPrice>
            <ContentColumn>
              <p>{t('landing.header.paragraph')}</p>
              <Link reloadDocument to='/onboarding'>
                <button
                  id='Header_ImInterested_Button'
                  onClick={() =>
                    SessionManager.setButtonId('Header_ImInterested_Button')
                  }
                  style={{ maxWidth: 250, minWidth: 250 }}
                >
                  {t('landing.header.button')}
                </button>
              </Link>
            </ContentColumn>
            <WrapperPrice id='WHY-ONCAMPUS'>
              <PricePrefix>
                {t('landing.header.price-prefix-0')}
                <Price>{t('landing.header.price-prefix-1')}</Price>
                {t('landing.header.price-prefix-2')}
              </PricePrefix>
            </WrapperPrice>
          </ContentPrice>
        </Content>
      </ContentWrapper>
    </HeaderWrapper>
  );
};

import { MainTemplate } from '../../template/main-template/main-template';
import { FlowWrapper } from '../../template/main-template/main-templateStyles';
import FormController from '../../newFlow/formController';
import { useAppSelector } from '../../../redux/hooks';
import { ProgressSteps } from '../../atoms/progress-bar/progress-steps';
import { Box } from 'grommet';
import { processOrderDetailText } from '../../../utils/text-processor';
import styled from 'styled-components';
import { device } from '../../../utils/use-device-type';
import { useTranslation } from 'react-i18next';

export const NewFlowPage = () => {
  const { currentTab, ...orderDetails } = useAppSelector(
    state => state.orderFlow
  );
  const { t } = useTranslation();

  const titleKey = `form-title-tab-${currentTab}`;
  const title = processOrderDetailText(t(titleKey), orderDetails);
  const displayTitle = title.includes(titleKey) ? '' : title;

  return (
    <FlowWrapper>
      <MainTemplate>
        <Box align='center' flex={false}>
          <ProgressSteps />
          <Title>{displayTitle}</Title>
          <FormController />
        </Box>
      </MainTemplate>
    </FlowWrapper>
  );
};

const Title = styled.h1`
  font-size: 1.2rem;
  margin: 20px auto;
  max-width: 80%;

  @media ${device.laptop} {
    font-size: 1.3rem;
    line-height: 1.6rem;
    max-width: 450px;
    margin: 10px auto 20px auto;
  }
`;

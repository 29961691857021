import { useTranslation } from 'react-i18next';
import { InfoBox, InfoIcon } from '../../atoms/info-box/info-box';

export const ContactBox = (props: { icon?: boolean }) => {
  const { t } = useTranslation();
  return (
    <InfoBox>
      {props.icon !== false && <InfoIcon fileName='oncampus-logo-symbol.svg' />}
      <div>
        <p>
          {t('info-contact-us')} <b>{t('info-reach-out')}</b>
        </p>
        <p>
          <b>{t('contact-email')}:</b> {t('contact-email-value')} <br />
          <b>{t('contact-phone')}:</b> {t('contact-phone-value')}
        </p>
        <p>
          <b>{t('greeting-safe-travels')}</b>
          <br /> {t('info-oncampus-team')}
        </p>
      </div>
    </InfoBox>
  );
};

import { useEffect, useRef } from "react";
export const HiddenForm = (props: { payment: any }) => {
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.submit();
    }
  }, []);
  return (
    <form
      ref={formRef}
      action={props.payment.url}
      method="POST"
      encType="application/x-www-form-urlencoded"
    >
      <input
        name="MerchantID"
        type="hidden"
        value={props.payment.data.MerchantID}
      />
      <input
        name="AcquirerBIN"
        type="hidden"
        value={props.payment.data.AcquirerBIN}
      />
      <input
        name="TerminalID"
        type="hidden"
        value={props.payment.data.TerminalID}
      />
      <input name="URL_OK" type="hidden" value={props.payment.data.URL_OK} />
      <input name="URL_NOK" type="hidden" value={props.payment.data.URL_NOK} />
      <input name="Firma" type="hidden" value={props.payment.data.Firma} />
      <input name="Cifrado" type="hidden" value={props.payment.data.Cifrado} />
      <input
        name="Num_operacion"
        type="hidden"
        value={props.payment.data.Num_operacion}
      />
      <input name="Importe" type="hidden" value={props.payment.data.Importe} />
      <input
        name="TipoMoneda"
        type="hidden"
        value={props.payment.data.TipoMoneda}
      />
      <input
        name="Exponente"
        type="hidden"
        value={props.payment.data.Exponente}
      />
      <input
        name="Pago_soportado"
        type="hidden"
        value={props.payment.data.Pago_soportado}
      />
      <input
        name="Descripcion"
        type="hidden"
        value={props.payment.data.Descripcion}
      />
      <input name="Idioma" type="hidden" value={props.payment.data.Idioma} />
    </form>
  );
};

import { OrderDetails } from './../models/order-details';
import { parseCountryCode } from '../models/countries';
import { parseDocumentType } from '../models/document-types';

export const processOrderDetailText = (label: string, orderDetails: OrderDetails) => {
  return label
    .replace('$first-name$', orderDetails.firstName)
    .replace('$last-name$', orderDetails.lastName)
    .replace('$home-country$', parseCountryCode(orderDetails.homeCountry))
    .replace('$destination-country$', parseCountryCode(orderDetails.destinationCountry))
    .replace('$birthday$', getReversedDate(orderDetails.birthday))
    .replace('$departure-date$', getReversedDate(orderDetails.departureDate))
    .replace('$return-date$', getReversedDate(orderDetails.returnDate))
    .replace('$document-type$', parseDocumentType(orderDetails.documentType))
    .replace('$document-number$', orderDetails.documentNumber)
    .replace('$email$', orderDetails.email)
    .replace('$phone-number$', orderDetails.phoneNumber)
    .replace('$order-id$', orderDetails.orderId ? orderDetails.orderId : '');
};

export const getReversedDate = (date: string, separator?: string): string =>
  date
    .split('-')
    .reverse()
    .join(separator ? separator : '-');

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OrderDetails } from "../../models/order-details";

const formatDate = (originalDate: string) => {
  const split = originalDate.split("/");
  if (split.length !== 3) return originalDate;
  const day = split[0];
  const month = split[1];
  const year = split[2];
  return `${year}-${month}-${day}`;
};
export enum OrderFlow {
  Flow1,
  Flow2,
}

export enum OrderFlowTabs {
  PersonalDetails,
  TravelDetails,
  IdentificationDetails,
  ExtraPersonalDetails,
  PersonalDetailsConfirmation,
  HealthCard,
  ExtendedInsurance,
  OrderSummary,
  ExtensionFlow,
  Payment,
  Loading,
  Error
}
export interface OrderFlowState extends OrderDetails {
  flow2Products: string;
  currentTab: OrderFlowTabs;
  flow: OrderFlow;
}

const initialState: OrderFlowState = {
  firstName: "",
  lastName: "",
  departureDate: "",
  returnDate: "",
  homeCountry: "",
  destinationCountry: "",
  documentType: "",
  documentNumber: "",
  birthday: "",
  phoneNumber: "",
  email: "",
  products: "",
  discount: false,
  discountCode: "",
  healthInsurance: true,
  holidayPackage: false,
  sportsPackage: false,
  newsConsent: false,
  legalConsentOne: false,
  legalConsentTwo: false,
  flow2Products: "",
  flow: OrderFlow.Flow1,
  currentTab: OrderFlowTabs.PersonalDetails,
};

const orderFlowSlice = createSlice({
  name: "orderFlow",
  initialState,
  reducers: {
    setPersonalDetails: (state, action) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
    },
    setTravelDetails: (state, action) => {
      state.homeCountry = action.payload.homeCountry;
      state.destinationCountry = action.payload.destinationCountry;
      state.departureDate = action.payload.departureDate;
      state.returnDate = action.payload.returnDate;
    },
    setIdentificationDetails: (state, action) => {
      state.documentType = action.payload.documentType;
      state.documentNumber = action.payload.documentNumber;
      state.birthday = action.payload.birthday;
    },
    setExtraPersonalDetails: (state, action) => {
      state.phoneNumber = action.payload.phoneNumber;
      state.email = action.payload.email;
    },
    setNewsConsent: (state, action) => {
      state.newsConsent = action.payload.newsConsent;
    },
    setLegalConsentOne: (state, action) => {
      state.legalConsentOne = action.payload.legalConsentOne;
    },
    setLegalConsentTwo: (state, action) => {
      state.legalConsentTwo = action.payload.legalConsentTwo;
    },
    setHealthCard: (state, action) => {
      state.healthInsurance = action.payload.healthInsurance;
    },
    setExtendedInsurance: (state, action) => {
      state.holidayPackage = action.payload.holidayPackage;
      state.sportsPackage = action.payload.sportsPackage;
    },
    setDiscount: (state, action) => {
      state.discount = action.payload.discount;
      state.discountCode = action.payload.discountCode;
    },
    setTab: (state, action: PayloadAction<OrderFlowTabs>) => {
      state.currentTab = action.payload;
    },
    setProducts: (state, action) => {
      state.products = action.payload.products;
    },
    setFlow: (state, action) => {
      state.flow = action.payload.flow;
    },
    setOrderDetails: (state, action) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.departureDate = formatDate(action.payload.departureDate);
      state.returnDate = formatDate(action.payload.returnDate);
      state.homeCountry = action.payload.homeCountry;
      state.destinationCountry = action.payload.destinationCountry;
      state.documentType = action.payload.documentType;
      state.documentNumber = action.payload.documentNumber;
      state.birthday = formatDate(action.payload.birthday);
      state.phoneNumber = action.payload.phoneNumber;
      state.email = action.payload.email;
      state.products = action.payload.products;
      state.discount = action.payload.discount;
      state.discountCode = action.payload.discountCode;
      state.healthInsurance = action.payload.healthInsurance;
      state.holidayPackage = action.payload.holidayPackage;
      state.sportsPackage = action.payload.sportsPackage;
      state.newsConsent = action.payload.newsConsent;
      state.legalConsentOne = action.payload.legalConsentOne;
      state.legalConsentTwo = action.payload.legalConsentTwo;
      state.checkHolidayPackage = action.payload.checkHolidayPackage;
      state.checkSportsPackage = action.payload.checkSportsPackage;
      state.flow2Products = action.payload.products;
      state.flow = OrderFlow.Flow2;
    },
    resetOrderFlow: (state) => {
      return initialState;
    },
  },
});
export const {
  setPersonalDetails,
  setTravelDetails,
  setIdentificationDetails,
  setExtraPersonalDetails,
  setNewsConsent,
  setLegalConsentOne,
  setLegalConsentTwo,
  setHealthCard,
  setExtendedInsurance,
  setDiscount,
  setTab,
  setProducts,
  resetOrderFlow,
  setFlow,
  setOrderDetails,
} = orderFlowSlice.actions;
export default orderFlowSlice.reducer;

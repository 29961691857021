import styled from 'styled-components';
import { device } from '../../../utils/use-device-type';

export const Wrapper = styled.div`
  background: ${props => props.theme.landing.colors.secondary};

  color: rgb(244, 244, 244);
  display: flex;
  flex-direction: column;
  > h2 {
    color: #f4f4f4;
  }
  padding: 0px 15px 35px 15px;
  @media ${device.laptop} {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 30px 0 30px;
  }
`;

export const ErgoContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${device.laptop} {
    flex-direction: row;
    align-items: center;
    width: 1100px;
  }
`;

export const ErgoLeftBox = styled.div`
  position: relative;
  height: 400px;
  @media ${device.mobileS} {
    width: 300px;
  }
  @media ${device.laptop} {
    height: 355px;
    width: 400px;
  }
  @media ${device.laptopL} {
    width: 450px;
  }
`;

export const ErgoBoxImage = styled.div`
  background: url('/assets/ergoMobile.png');
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  height: 100%;
  width: 350px;
  @media ${device.mobileS} {
    width: 300px;
    bottom: -17px;
  }
  @media ${device.laptop} {
    background: url('/assets/ergoLaptop.png');
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    width: 400px;
    bottom: -33px;
  }
  @media ${device.laptopL} {
    width: 450px;
    bottom: 0;
  }
`;
export const ErgoLogo = styled.div`
  @media ${device.laptop} {
    background: url('/assets/ergoLogo.png');
    background-size: contain;
    background-repeat: no-repeat;
    height: 64px;
    width: 100px;
  }
`;
export const ErgoBar = styled.div`
  width: 100%;
  height: 4px;
  background-color: #06b192;
  @media ${device.laptop} {
    height: 7px;
  }
`;
export const ErgoMainBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 15px;
`;
export const ErgoText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media ${device.laptop} {
    height: 130px;
    width: 70%;
    padding-top: 20px;
  }
  @media ${device.laptopL} {
    padding-top: 0;
  }
`;
export const Box = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const BoldSubtitle = styled.h5`
  font-weight: bold;
  font-size: 1.8rem;
  @media ${device.laptop} {
    font-size: 1rem;
  }
`;
export const RegularText = styled.p`
  font-size: 1.1rem;
  margin-bottom: 10px;
  max-width: 600px;
  @media ${device.laptop} {
    font-size: 0.9rem;
  }
`;

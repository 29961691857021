import { SessionManager } from "./session-manager";

export const saveQueryParamas = (currentUrl: string): void => {
  const urlParams = new URLSearchParams(currentUrl);
  const utm_source = urlParams.get("utm_source");
  const utm_medium = urlParams.get("utm_medium");
  const utm_campaign = urlParams.get("utm_campaign");
  SessionManager.setUtmSource(utm_source ?? SessionManager.getUtmSource());
  SessionManager.setUtmMedium(utm_medium ?? SessionManager.getUtmMedium());
  SessionManager.setUtmCampaign(
    utm_campaign ?? SessionManager.getUtmCampaign()
  );
};

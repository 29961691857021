import Amplify from "aws-amplify";

Amplify.configure({
    API: {
        endpoints: [
            {
                name: "oncampus-api",
                //@ts-ignore
                endpoint: window.OPSEEKER_CONFIG.API_ENDPOINT,
                region: "eu-west-1"
            }
            ,
            {
                name: "oncampus-notification-api",
                //@ts-ignore
                endpoint: window.OPSEEKER_CONFIG.API_LANDING_ENDPOINT,
                region: "eu-west-1"
            } ,
            {
                name: "oncampus-token-api",
                //@ts-ignore
                endpoint: window.OPSEEKER_CONFIG.API_TOKEN_ENDPOINT,
                region: "eu-west-1"
            },
            {
                name: "staging-oncampus-contact-api",
                //@ts-ignore
                endpoint: window.OPSEEKER_CONFIG.API_CONTACT_ENDPOINT,
                region: "eu-west-1"
            }
        ]
    }
});
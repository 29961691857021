import styled from 'styled-components';
import { useAppSelector } from '../../../redux/hooks';
import { OrderFlowTabs } from '../../../redux/orderFlow/orderFlowSlice';
import { device } from '../../../utils/use-device-type';

interface ProgressBarProps {
  completed: number;
}

export const ProgressSteps = () => {
  const currentTab = useAppSelector(state => state.orderFlow.currentTab);
  const completed = (currentTab / 8) * 100;

  if (
    currentTab !== OrderFlowTabs.OrderSummary &&
    currentTab !== OrderFlowTabs.PersonalDetailsConfirmation &&
    currentTab !== OrderFlowTabs.Payment &&
    currentTab !== OrderFlowTabs.ExtensionFlow &&
    currentTab !== OrderFlowTabs.Error &&
    currentTab !== OrderFlowTabs.Loading
  )
    return (
      <ProgressBarWrapper>
        <Progress completed={completed} />
      </ProgressBarWrapper>
    );

  return null;
};

const ProgressBarWrapper = styled.div`
  margin-top: 20px;
  height: 8px;
  width: 80%;
  background: ${props => props.theme.global.colors.neutral};
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 20px;
  @media ${device.tablet} {
    max-width: 400px;
  }
`;

const Progress = styled.div<ProgressBarProps>`
  height: 100%;
  width: ${props => props.completed}%;
  background: ${props => props.theme.landing.colors.third};
`;

import styled, { createGlobalStyle } from 'styled-components';
import { device } from '../../../utils/use-device-type';

//@ts-ignore
export const MEDIA_URL = window.OPSEEKER_CONFIG.CDN_URL + '/images';
//@ts-ignore
export const MEDIA_URL_ROOT = window.OPSEEKER_CONFIG.CDN_URL;

export const PageWrapper = styled.div`
  background: url('${MEDIA_URL}/pattern-oncampus.png') #f4ede8;
  background-size: contain;
  color: ${props => props.theme.landing.colors.primary};
  overflow-x: hidden;
  /* position: relative; */
`;

export const FlowWrapper = styled.div`
  background: ${props => props.theme.landing.colors.secondary};
  height: 100vh;
`;

export const PageDeco = styled.div`
  @media ${device.laptopL} {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 400px;
    height: 400px;
    background: url('${MEDIA_URL}/ONCampus_students.png') no-repeat;
    background-size: contain;
    background-position: bottom right;
  }
`;
export const PageContent = styled.div`
  padding: 50px 0;
  position: relative;
  @media (min-width: 991px) {
    padding: 150px 0;
    min-height: 700px;
  }
`;

export const InfoPage = styled.div`
  background: #ffffff;
  border-radius: 10px;
  padding: 15px;
  margin: 0 auto;
  max-width: 90%;
  > p {
    max-width: 100%;
  }
  > ul,
  > ol {
    margin-left: 40px;
    font-size: 1rem;
    > li {
      margin: 8px 0;
    }
  }
  > h2 {
    margin: 50px 0 20px 0;
    text-align: left;
  }
  > h3 {
    font-size: 1.3rem;
    text-align: left;
  }
  > table {
    border-collapse: collapse;
    margin: 30px auto;
    max-width: 80%;

    > tr td {
      border: 1px solid;
      padding: 10px;
    }
  }
  @media (${device.laptop}) {
    padding: 50px;
    max-width: 900px;
  }
`;

export const Button = styled.button<{
  $secondary?: boolean;
  disabled?: boolean;
}>`
  background: ${props =>
    props.disabled
      ? '#cccccc'
      : props.$secondary
      ? 'transparent'
      : props.theme.landing.colors.third};
  color: ${props =>
    props.$secondary
      ? props.theme.landing.colors.third
      : props.theme.landing.colors.textNegative};
  border-radius: 30px;
  text-transform: uppercase;
  border: 2px solid
    ${props =>
      props.$secondary ? props.theme.landing.colors.third : 'transparent'};
  margin: 10px 0;
  @media (${device.laptop}) {
    cursor: pointer;
  }
`;

export const Subheading = styled.p`
  font-size: 1rem;
  text-align: center;
  line-height: 1.1rem;
  margin: 10px auto;
  font-weight: bold;
  @media (min-width: 991px) {
    font-size: 1.1rem;
    line-height: 1.3rem;
    margin: 10px auto 30px auto;
  }
`;

export const Warning = styled.div`
  color: ${props => props.theme.landing.colors.textNegative};
  background: url('${MEDIA_URL}/OnCampus-icon-star.png')
    ${props => props.theme.landing.colors.negativeLighter} no-repeat;
  background-size: 10px;
  background-position: 10px;
  border: 1px solid ${props => props.theme.landing.colors.negative};
  padding: 3px 10px 3px 25px;
  border-radius: 5px;
  margin-bottom: 10px;
`;

//@ts-ignore
export const GlobalStyle = createGlobalStyle<{ theme: ThemeType }>`
 
*,body,html {margin:0; padding:0}
  body,html, p, input, button{margin:0;padding:0; font-size: 10px;font-size: 1rem;}
  body {
  background: #f4ede8;
  font-family:${props => `${props.theme.font}, 'Trebuchet MS', sans-serif`};
}

a:link,a:visited,a:hover,a:active{
  color:#ffffff; font-weight:bold;
  @media (${device.laptop}) {
    cursor: pointer;
    >button{width:100%;max-width:450px;}
  }
}
a:hover{opacity:.8}

h1,h2,h3{
  font-size: 14px;
  font-size: 1.4rem;
  color: #555586;
  color: ${props => props.theme.landing.colors.primary};
  font-weight:bold;
  font-family: Helvetica, Merriweather, serif;
  letter-spacing:0.1rem;
  text-align:center;
}

body.fontLoaded h1, body.fontLoaded h2,body.fontLoaded h3{
  font-family: Merriweather, serif;
}

h1{font-size:1.3rem;}
h2{font-size:1.5rem;}
  
button,input[type="submit"] {
  background: ${props => props.theme.landing.colors.third};
  color: ${props => props.theme.landing.colors.textNegative};
  border-radius: 30px;
  text-transform: uppercase;
  font-weight: bold;
  margin: 10px 0;
  padding: 15px 10px;
  border: 0;
  display: inline-block;
    outline: none;
}

button:hover{opacity:.8}

p{margin: 10px 0; }

form{font-size:1rem;padding:20px;}


  
input, textarea{
  width: 100%;
  height: 60px;
  border: 0px;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #000000;
  padding: 10px 20px;
  cursor:pointer;
  &.form-control{margin:0}
}

textarea {height: 100px;}

form .checks{
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  }
.checks label{padding-left: 35px;}
.checks input[type="checkbox"]{
  position: absolute;
  cursor: pointer;
  opacity:0;
  margin:0;
  padding:0;
  width:100%;
  z-index:100
}

.checkmark{
  position: absolute;
  top: 0;
  left: 0;
  background-color: #eee;
  width: 25px;
  border-radius:5px;
}

.checkmark,.checks input[type="checkbox"]{height: 25px;}

.checks:hover input ~ .checkmark {background-color: #ccc;}

.checks input:checked ~ .checkmark {
  background-color: ${props => props.theme.landing.colors.third};
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checks input:checked ~ .checkmark:after {display: block;}

.checks .checkmark:after {
  left: 9px;
  top: 3px;
  width: 10px;
  height: 15px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(40deg);
  -ms-transform: rotate(40deg);
  transform: rotate(40deg);
}
  

button,input[type="submit"]{min-width: 80%;cursor:pointer;}

@media (min-width: 600px) {
  p{max-width:400px}
  button,input[type="submit"]{min-width: 300px;cursor:pointer;}
  
}
  @media (min-width: 991px) {
    h1,h2,h3{ font-size: 18px;font-size: 1.8rem;}
    h1{font-size:1.3rem;line-height:1.6rem;max-width:450px;margin:10px auto 20px auto;}
    form {max-width:500px;margin:0 auto}
  }
`;

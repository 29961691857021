import styled from 'styled-components';
import { MEDIA_URL } from '../../template/main-template/main-templateStyles';
import { device } from '../../../utils/use-device-type';

export const HeaderWrapper = styled.div`
  width: 100%;
  height: 600px;
  padding: 70px 15px 70px 15px;
  background: url('${MEDIA_URL}/mobile/ONCampus_cover-bg.webp') no-repeat;
  background-size: contain;
  position: relative;
  text-align: center;
  background-position: 55% bottom;

  @media ${device.mobileL} {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-position: center bottom;
  }
  @media ${device.splitScreen} {
    height: 700px;
  }
  @media ${device.tablet} {
    height: 800px;
  }

  @media ${device.laptop} {
    background-image: url('${MEDIA_URL}/ONCampus_cover-bg.webp');
    background-size: 180%;
    height: 100vh;
    align-items: flex-start;
    padding-top: 200px;
    background-position: center bottom;
  }

  @media ${device.desktop} {
    background-position: center bottom;
    background-size: 120%;
  }
  @media ${device.iPadPro} {
    height: 800px;
  }
`;

export const ContentWrapper = styled.div`
  padding-top: 40px;

  @media ${device.mobileL} {
    padding-top: 0;
  }

  @media ${device.landscape} {
    width: 70%;
    padding: 0 20px;
  }
  @media ${device.tablet} {
    padding-top: 0;
  }
  @media ${device.laptop} {
    text-align: left;
    width: 900px;
    margin: 0 auto;
  }
  @media ${device.laptopL} {
    width: 1000px;
    padding: 0 60px;
  }
`;
export const PortugalTitle = styled.h1`
  color: #0ab192;
  font-size: 1.1rem;
  margin-bottom: 10px;
  text-align: center;
  margin: 0 auto;
  @media ${device.mobileL} {
    max-width: 400px;
  }
  @media ${device.tablet} {
    max-width: 500px;
  }
  @media ${device.landscape} {
    text-align: left;
    font-size: 1.2rem;
    line-height: 1.4rem;
    margin: 0;
  }
  @media ${device.laptop} {
    margin: 20px 0;
    text-align: left;
    font-size: 1.7rem;
    line-height: 1.9rem;
    max-width: 600px;
  }
  @media ${device.laptopL} {
    max-width: 750px;
  }
  @media ${device.fold} {
    font-size: 1.2rem;
    line-height: 1.4rem;
  }
`;

export const Title = styled.h1`
  text-align: center;
  margin: 0 auto;
  font-size: 1rem;
  @media ${device.mobileL} {
    max-width: 400px;
  }
  @media ${device.tablet} {
    max-width: 500px;
  }
  @media ${device.landscape} {
    text-align: left;
    font-size: 1.1rem;
    line-height: 1.4rem;
    margin: 0;
  }
  @media ${device.laptop} {
    margin: 20px 0;
    text-align: left;
    font-size: 1.6rem;
    line-height: 1.9rem;
    max-width: 600px;
  }
  @media ${device.laptopL} {
    max-width: 750px;
  }
  @media ${device.fold} {
    font-size: 1.1rem;
    line-height: 1.4rem;
  }
`;

export const ContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  @media ${device.landscape} {
    align-items: flex-start;
    text-align: left;
  }
  @media ${device.laptop} {
    align-items: flex-start;
    text-align: left;
  }
`;

export const Content = styled.div`
  @media ${device.mobileL} {
    padding: 0 30px;
  }
  @media ${device.laptop} {
    display: flex;
    flex-direction: column;
  }
`;
export const ContentPrice = styled.div`
  @media ${device.laptop} {
    display: flex;
  }
`;

export const WrapperPrice = styled.div`
  padding: 5px;
  color: #ffffff;
  @media ${device.mobileL} {
    padding: 15px;
  }
  @media ${device.landscape} {
    padding-top: 5px;
  }
  @media ${device.landscape} and (max-device-height: 280px) {
    display: none;
  }
  @media ${device.landscape} and (min-device-width: 1024px) {
    padding-top: 15px;
    display: block;
  }
  @media ${device.laptop} {
    background: ${props => props.theme.landing.colors.primary};
    border-radius: 50%;
    height: 110px;
    width: 110px;
    padding: 18px 5px 20px 22px;
  }
`;

export const PricePrefix = styled.p`
  font-size: 0.9rem;
  margin: 0 auto;
  margin-top: 5px;
  color: ${props => props.theme.landing.colors.primary};
  @media ${device.tablet} {
    font-size: 0.8rem;
    margin: 0 auto;
    text-align: center;
  }
  @media ${device.laptop} {
    font-size: 0.9rem;
    display: inline-block;
    color: #ffffff;
    margin: 0;
  }
  @media ${device.landscape} {
    margin: 0;
    text-align: left;
  }
`;
export const Price = styled.span`
  font-family: 'Merriweather', serif;
  color: ${props => props.theme.landing.colors.third};
  font-weight: bold;
  font-size: 1.9rem;
  margin: 8px;
  text-align: center;
  @media ${device.laptop} {
    font-size: 2rem;
    margin: 5px auto;
    display: block;
    line-height: 80%;
  }
`;

export const ProductPrefix = styled.p`
  color: ${props => props.theme.landing.colors.third};
  font-size: 2rem;
  font-family: 'Merriweather', serif;
  text-align: left;
  font-weight: bold;
  margin: 40px auto;

  @media (min-width: 400px) {
    text-align: center;
  }
  @media (min-width: 600px) {
    font-size: 4.5rem;
    text-align: left;
    padding-left: 0;
    margin: 40px 0;
  }
  @media (min-width: 991px) {
    text-align: center;
  }
`;

export const ProductHeader = styled.div`
position:relative;
>div{max-width:400px}
@media (min-width: 600px) {>div{max-width:100% }
`;
export const WrapperProductHeader = styled.div`
  background: ${props => props.theme.landing.colors.primary};
  opacity: 0.95;
  padding: 15px;
  text-align: left;
  color: #ffffff;
  border-radius: 5px;
  margin: 0 auto;
  > h1 {
    color: #ffffff;
  }
  @media (min-width: 600px) {
    margin: 0;
    > p {
      max-width: 350px;
    }
  }
  @media (min-width: 991px) {
    background: url('${MEDIA_URL}/ONCampus_circle_bg.png') left 10px no-repeat;
    background-size: contain;
    height: 500px;
    width: 500px;
    padding: 75px 55px 0 55px;
    > p,
    > h1 {
      max-width: 100%;
    }
    > h1 {
      font-size: 1.3rem;
    }
  }
`;

import styled from 'styled-components';
import { MEDIA_URL } from '../../template/main-template/main-templateStyles';
import { device } from '../../../utils/use-device-type';
export const Wrapper = styled.div`
  background-color: ${props => props.theme.landing.colors.secondary};
  > p {
    color: #f4f4f4;
    padding: 0 40px;
  }
`;

export const LegalPages = styled.div`
  background: url('${MEDIA_URL}/oncampus_line.png') repeat-x;
  background-size: 700px 15px;
  background-color: ${props => props.theme.landing.colors.secondary};
  text-align: center;
  padding: 30px 0 20px 0;
  margin: 0 auto;
  > div a.menuLink:link,
  > div a.menuLink:visited,
  > div a.menuLink:hover,
  > div a.menuLink:active {
    display: inline-block;
    padding: 0 15px;
    text-decoration: none;
    font-weight: normal;
    color: #ffffff;
    font-size: 0.7rem;
    background: none;
  }
  > div button {
    display: none;
  }
  #social-media {
    display: flex;
    justify-content: center;
    margin: 20px auto 10px auto;
    gap: 10px;
  }

  @media (min-width: 600px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const LegalProvider = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 8px;
  color: #ffffff;
  > p {
    font-size: 0.8rem;
  }

  @media (min-width: 991px) {
  }
  @media ${device.laptopL} {
    margin: 0 auto;
  }
`;

export const ProviderLogo = styled.a`
  display: inline-block;
  background: url('${MEDIA_URL}/seguros-de-viaje-ergo.png')
    ${props => props.theme.landing.colors.secondary} no-repeat;
  background-size: contain;
  width: 60px;
  height: 50px;
  cursor: pointer;
  text-decoration: none;
  > span {
    color: transparent;
  }
`;

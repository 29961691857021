import { OrderDetails } from "./../../models/order-details";
import { API } from "aws-amplify";
import { initialApiState, ApiCallBase } from "./apiConfiguration";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface Data {
  MerchantID: string;
  AcquirerBIN: string;
  TerminalID: string;
  Num_operacion: string;
  Importe: number;
  TipoMoneda: string;
  Exponente: string;
  URL_OK: string;
  URL_NOK: string;
  Firma: string;
  Cifrado: string;
  Idioma: string;
  Pago_soportado: string;
  Descripcion: string;
}

interface ApiResponse {
  httpCode: number;
  orderDetails: OrderDetails;
  paymentUrl: string;
  data: Data;
}

interface OrderDetailsCall extends ApiCallBase {
  response: ApiResponse;
}
interface DiscountCodeCall extends ApiCallBase {
  response: {
    discount: boolean;
    discountRate?: number;
  };
}

interface checkIdRepetitionCall extends ApiCallBase {
  response: {
    httpCode: number;
    ch: boolean;
  };
}

interface State {
  orderDetails: OrderDetailsCall;
  discountValidation: DiscountCodeCall;
  checkIdRepetition: checkIdRepetitionCall;
}

const initialState: State = {
  orderDetails: initialApiState,
  discountValidation: initialApiState,
  checkIdRepetition: initialApiState,
};

export const postOrderDetails = createAsyncThunk(
  "orderDetails/postOrderDetails",
  async (args: OrderDetails) => {
    try {
      const OrderResponse = await API.post("oncampus-api", "", {
        response: true,
        queryStringParameters: {
          action: "post-order-details",
        },
        body: {
          ...args,
        },
      });
      return OrderResponse.data;
    } catch (error) {
      return console.log(error);
    }
  }
);

export const discountCodeValidation = createAsyncThunk(
  "orderDetails/discountCodeValidation",
  async (discountCode: string) => {
    try {
      const discountCodeValidationResponse = await API.post(
        "oncampus-api",
        "",
        {
          response: true,
          queryStringParameters: {
            action: "discount-code-validation",
          },
          body: {
            discountCode,
          },
        }
      );
      return discountCodeValidationResponse.data;
    } catch (error) {
      return console.log(error);
    }
  }
);

export const checkIdRepetition = createAsyncThunk(
  "orderDetails/checkIdRepetition",
  async (documentNumber: string) => {
    try {
      const checkIdRepetitionResponse = await API.post("oncampus-api", "", {
        response: true,
        queryStringParameters: {
          action: "check-id-repetition",
        },
        body: {
          documentNumber,
        },
      });
      return checkIdRepetitionResponse.data;
    } catch (error) {
      return console.log(error);
    }
  }
);

const OrderDetailsSlice = createSlice({
  name: "orderDetails",
  initialState,
  reducers: {
    resetOrderDetails: (state) => {
      state.orderDetails = {
        ...initialApiState,
        response: state.orderDetails.response,
      };
    },
    resetDiscountCodeValidation: (state) => {
      state.discountValidation = {
        ...initialApiState,
        response: state.discountValidation.response,
      };
    },
    resetDiscountCodeValidationResponse: (state) => {
      state.discountValidation = {
        ...initialApiState,
      };
    },
    resetCheckIdRepetition: (state) => {
      state.checkIdRepetition = {
        ...initialApiState,
        response: state.checkIdRepetition.response,
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(postOrderDetails.pending, (state) => {
      state.orderDetails.loading = "pending";
    });
    builder.addCase(postOrderDetails.fulfilled, (state, action) => {
      state.orderDetails.loading = "succeeded";
      state.orderDetails.response = action.payload;
    });
    builder.addCase(postOrderDetails.rejected, (state, action) => {
      state.orderDetails.loading = "failed";
      state.orderDetails.error = action.payload;
    });
    builder.addCase(discountCodeValidation.pending, (state) => {
      state.discountValidation.loading = "pending";
    });
    builder.addCase(discountCodeValidation.fulfilled, (state, action) => {
      state.discountValidation.loading = "succeeded";
      state.discountValidation.response = action.payload;
    });
    builder.addCase(discountCodeValidation.rejected, (state, action) => {
      state.discountValidation.loading = "failed";
      state.discountValidation.error = action.payload;
    });
    builder.addCase(checkIdRepetition.pending, (state) => {
      state.checkIdRepetition.loading = "pending";
    });
    builder.addCase(checkIdRepetition.fulfilled, (state, action) => {
      state.checkIdRepetition.loading = "succeeded";
      state.checkIdRepetition.response = action.payload;
    });
    builder.addCase(checkIdRepetition.rejected, (state, action) => {
      state.checkIdRepetition.loading = "failed";
      state.checkIdRepetition.error = action.payload;
    });
  },
});

export const {
  resetOrderDetails,
  resetDiscountCodeValidation,
  resetDiscountCodeValidationResponse,
  resetCheckIdRepetition
} = OrderDetailsSlice.actions;
export default OrderDetailsSlice.reducer;

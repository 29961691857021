import {
  Wrapper,
  DownloadsWrapper,
  DownloadLinks,
  Doc,
  TextLink,
  Title,
  Icon,
  DownloadContent,
} from './productPolicyStyles';
import { downloads } from '../../../models/product-details';
import { MEDIA_URL_ROOT } from '../../template/main-template/main-templateStyles';
import { useTranslation } from 'react-i18next';

export const Downloads = () => {
  const { t } = useTranslation();
  return (
    <DownloadsWrapper>
      <Wrapper>
        <h2>{t('landing.downloads.title')}</h2>
        <DownloadLinks>
          {downloads.map((d, i) => (
            <Doc key={d.icon}>
              <Icon fileName={d.icon} sequence={i} />
              <DownloadContent>
                <Title>{t(`landing.downloads.${d.title}`)}</Title>
                <TextLink
                  href={`${MEDIA_URL_ROOT}/documents/${d.fileName}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {t('landing.downloads.text-link')}
                </TextLink>
              </DownloadContent>
            </Doc>
          ))}
        </DownloadLinks>
      </Wrapper>
    </DownloadsWrapper>
  );
};

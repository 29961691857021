import styled from 'styled-components';
import { SessionManager } from '../../../utils/session-manager';
import { device } from '../../../utils/use-device-type';
import React, { ReactNode } from 'react';
import { renderParagraphsJson } from '../../../utils/utils';
import { useTranslation } from 'react-i18next';

export const DropDownV2 = (props: {
  id: number;
  title: string;
  content?: ReactNode[];
  expanded: null | number;
  setExpanded: any;
}) => {
  const isExpanded = props.expanded === props.id;
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Title onClick={() => props.setExpanded(isExpanded ? null : props.id)}>
        <TitleLabel expanded={isExpanded}>{props.title}</TitleLabel>
        <p>
          <ButtonNaked
            id='DropDown_expand_Button'
            onClick={() => SessionManager.setButtonId('DropDown_expand_Button')}
            expanded={isExpanded}
          >
            {isExpanded ? '-' : '+'}
          </ButtonNaked>
        </p>
      </Title>
      {isExpanded && (
        <>
          {[1, 2, 3].map(item => (
            <Content key={item}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {renderParagraphsJson(
                  t(`landing.frequent-questions.faqExtra.content-${item}`)
                )}
                <ClickableLink
                  href={t(`landing.frequent-questions.faqExtra.path-${item}`)}
                >
                  {t(`landing.frequent-questions.faqExtra.link-${item}`)}
                </ClickableLink>
              </div>
            </Content>
          ))}
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 8px;
  border-bottom: 1px solid #e7e2df;
  @media (min-width: 380px) {
    padding: 8px 30px 8px 20px;
  }
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  cursor: pointer;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  > p {
    max-width: 90%;
    text-align: left;
    font-size: 0.8rem;
  }
  @media ${device.tablet} {
    > p {
      font-size: 1rem;
      text-align: left;
      padding-left: 30px;
    }
  }
`;

const ClickableLink = styled.a`
  text-align: left;
  font-size: 0.8rem;
  font-weight: bold;
  color: #263287 !important;
  text-decoration: none;
  cursor: pointer;
  margin-left: 0.5rem;
`;

export const ButtonNaked = styled.span<{ expanded?: boolean }>`
  display: block;
  background: transparent;
  padding: 0;
  margin: 0;
  min-width: 0;
  font-weight: bold;
  font-size: 1.8rem;
  color: ${props =>
    props.expanded
      ? props.theme.landing.colors.primary
      : props.theme.landing.colors.third};
`;

export const TitleLabel = styled.p<{ expanded: boolean }>`
  max-width: 90%;
  font-size: 0.8rem;
  text-align: left;
  color: ${props =>
    props.expanded
      ? props.theme.landing.colors.third
      : props.theme.landing.colors.primary};
  @media (min-width: 380px) {
    font-size: 1rem;
  }
`;

import { configureStore } from "@reduxjs/toolkit";
import orderFlowReducer from "./orderFlow/orderFlowSlice";
import OrderApiReducer from "./api/OrderApi";
import SecondFlowReducer from "./api/SecondFlowApi";

export const store = configureStore({
  reducer: {
    orderFlow: orderFlowReducer,
    orderApi: OrderApiReducer,
    secondFlowApi: SecondFlowReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  })
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
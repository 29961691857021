
export enum Products {
	Main,
	MainSports,
	MainHolidays,
	MainSportsHolidays,
	Sports,
	Holidays,
	SportsHolidays
}
export interface OrderDetails {
    orderId?: string;
    firstName: string;
    lastName: string;
    departureDate: string;
    returnDate: string;
    homeCountry: string;
    destinationCountry: string;
    documentType: string;
    documentNumber: string;
    birthday: string;
    phoneNumber: string;
    email: string;
    createdAt?: string;
    amount?: number;
    products?: string;
    currency?: string;
    discount?: boolean,
    discountCode?: string,
    healthInsurance: boolean
    holidayPackage?: boolean,
    sportsPackage?: boolean,
    newsConsent: boolean,
    legalConsentOne: boolean,
    legalConsentTwo: boolean,
    checkHolidayPackage?: boolean,
    checkSportsPackage?: boolean,
    userSession?: string | null,
}
export interface UserDetails {
    timestamp: string,
    firstName: string,
    lastName: string,
    departureDate: string,
    returnDate: string,
    homeCountry: string,
    destinationCountry: string,
    documentType: string,
    documentNumber: string,
    birthday: string,
    phoneNumber: string,
    email: string,
    products: string,
    createdAt: string,
    amount: number,
    currency: string,
    discount: boolean,
    discountCode: string,
    healthInsurance: boolean,
    holidayPackage: boolean,
    sportsPackage: boolean,
    newsConsent: boolean,
    legalConsentOne: boolean,
    legalConsentTwo: boolean,
    checkHolidayPackage?: boolean,
    checkSportsPackage?: boolean,
    userSession?: string,
}

export interface ExtendedInsurance {
    idNumber: string;
}

export interface PaymentDetails {
    orderId: string;
    state: 'paid' | 'in-process' | 'error';
}

import { Link } from 'react-router-dom';
import { SessionManager } from '../../../utils/session-manager';
import { Container, Img, Pack, Packs } from './productPolicyStyles';
import { useTranslation } from 'react-i18next';

export const ProductPolicy = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <h2>
        {t('landing.productPolicy.title-0')}
        <span className='accent'>{t('landing.productPolicy.title-1')}</span>
        {t('landing.productPolicy.title-2')}
      </h2>
      <Packs>
        <Pack type='sports'>
          <Img type='sports' />
          <div>
            <h3>{t('landing.productPolicy.pack-1-title')}</h3>
            <p>{t('landing.productPolicy.pack-1-paragraph')} </p>
          </div>
        </Pack>
        <Pack type='holiday'>
          <Img type='holiday' />
          <div>
            <h3>{t('landing.productPolicy.pack-2-title')}</h3>
            <p>{t('landing.productPolicy.pack-2-paragraph')} </p>
          </div>
        </Pack>
      </Packs>
      <p>{t('landing.productPolicy.paragraph-1')}</p>
      <Link reloadDocument to='/onboarding?extendedInsurance=true'>
        <button
          id='ProductPolicy_extendMyInsurance_Button'
          onClick={() => SessionManager.setButtonId('ProductPolicy_extendMyInsurance_Button')}
        >
          {t('landing.productPolicy.button')}
        </button>
      </Link>
    </Container>
  );
};

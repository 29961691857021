import styled from 'styled-components';
import { device } from '../../../utils/use-device-type';

export const CrossIcon = styled.img`
  width: 18px;
  height: 18px;
  cursor: pointer;
`;

export const DiscountIcon = styled.img`
  width: 20px;
  height: 20px;
`;

export const DiscountBox = styled.div`
  align-self: right;
  padding: 3px 15px 3px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #263287;
  border-radius: 25px;
  > p {
    font-size: 0.9rem;
    padding: 0px 5px 0px 5px;
  }
  width: auto;
  @media ${device.tablet} {
    width: 100%;
    > p {
      font-size: 0.9rem;
      padding: 0px 10px 0px 10px;
    }
  }
`;

export const TablePrices = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 0px;
`;

export const DiscountContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
export const DiscountButton = styled.button`
  padding: 2px;
  font-size: 0.6rem;
  line-height: 1rem;
  margin-left: 10px;
  min-width: 100px;
  max-width: 100px;
`;

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Separationbar = styled.div`
  width: 100%;
  height: 1px;
  background-color: grey;
`;

export const downloads = [
   {
    id: 'doc-table-of-contents',
    fileName: 'OnCampusAbroad_Table-of-Contents.pdf',
    icon: 'triangle',
    title: 'file-2',
  },
  {
    id: 'doc-packages',
    fileName: 'OnCampus_Extension-Packages_conditions.pdf',
    icon: 'plane',
    title: 'file-3',
  },
  {
    id: 'doc-ipid',
    fileName: 'IPID_OnCampus_Abroad.pdf',
    icon: 'umbrella',
    title: 'file-1',
  },
];

import { Box } from "grommet";
import { useGetLambdaVersion } from "../../../api/use-get-lambda-version";

export const VersionPage = () => {
  const version = useGetLambdaVersion();
  //@ts-ignore
  const webappVersion = window.FRONTEND_TIMESTAMP;
  return (
    <Box>
      <Box>{JSON.stringify(version)}</Box>
      <Box>{`Frontend webapp: ${webappVersion}`}</Box>
    </Box>
  );
};

import { Countries, Country } from '../models/countries';

import { FormField } from '../models/form';
import {
  MAX_RETURN_DATE,
  MAXIMUM_PERIOD_MONTHS,
  getMonthsDifference,
  deserializeDate,
  serializeDate,
  getMaxDepartureDate,
} from '../components/atoms/date-picker/date-picker-functions';
import { getMonthsNumber } from './prices-calculator';
import { subDays, isValid } from 'date-fns';
const MAX_CHARS_DOCUMENTNO = 20;

type ValidateF = (input: string) => boolean;
type ValidateNewCountryF = (input: string, countries: Country[]) => boolean;

export const validateString: ValidateF = (input: string) => {
  const specialCharFormat = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  return !!input && !specialCharFormat.test(input);
};

export const validateName: ValidateF = (input: string) => {
  const nameFormat = /^[^\p{P}\p{N}]+$/u;
  return nameFormat.test(input);
};
export const validateFutureDate: ValidateF = (input: string) => {
  const split = input.split('-');
  if (split.length < 3) return false;
  const date = deserializeDate(input);
  return date.getTime() > Date.now();
};

export const validatePhoneNumber: ValidateF = (input: string) => !!input;
export const validatePastDate: ValidateF = (input: string) => {
  const split = input.split('-');
  if (split.length < 3) return false;
  const date = deserializeDate(input);
  return date.getTime() < Date.now();
};

export const validateEmail: ValidateF = (input: string) => {
  const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return emailFormat.test(input);
};

export const validateCountry: ValidateF = (input: string) =>
  Countries.some((country) => country.c === input);

export const validateNewCountry: ValidateNewCountryF = (input: string, countries: Country[]) =>
  countries.some((country) => country.c === input);

export const validateField = (field: FormField, value: string): boolean => {
  switch (field.type) {
    case 'birthday':
      return validatePastDate(value);
    case 'future-date':
      return validateFutureDate(value);
    case 'email':
      return validateEmail(value);
    case 'country':
      return validateCountry(value);
    default:
      return validateString(value);
  }
};

export const limitDocumentNumber = (input: string): string => {
  if (input.length <= MAX_CHARS_DOCUMENTNO) return input;
  return input.slice(0, MAX_CHARS_DOCUMENTNO);
};

export const validateReturnDate = (returnDate: string, departureDate: string) => {
  const months = getMonthsNumber(returnDate, departureDate);
  const monthsDiff = getMonthsDifference(
    returnDate,
    serializeDate(subDays(deserializeDate(departureDate), 1))
  );

  let message = '';
  if (!validateFutureDate(returnDate) || !isValid(deserializeDate(returnDate))) {
    message = 'format';
  } else if (monthsDiff < 1) {
    message = 'min-months';
  } else if (returnDate > MAX_RETURN_DATE && months < MAXIMUM_PERIOD_MONTHS) {
    message = 'max-date';
  } else if (months > MAXIMUM_PERIOD_MONTHS) {
    message = 'max-duration';
  }
  return message;
};

export const validateDepartureDate = (returnDate: string, departureDate: string) => {
  const monthsDiff = getMonthsDifference(
    returnDate,
    serializeDate(subDays(deserializeDate(departureDate), 1))
  );
  let message = '';

  if (!validateFutureDate(departureDate) || !isValid(deserializeDate(departureDate))) {
    message = 'format';
  } else if (monthsDiff > MAXIMUM_PERIOD_MONTHS) {
    message = 'max-duration';
  } else if (departureDate > getMaxDepartureDate()) {
    message = 'max-date';
  }
  return message;
};

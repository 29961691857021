import Lottie from "lottie-react";
import error from "./LottieJson/error-gif.json";
import styled from "styled-components";

export const Error = (props: { text: string }) => (
  <ErrorWrapper>
    <Lottie animationData={error} loop={false} style={{ height: 100 }} />
    <p>{props.text}</p>
  </ErrorWrapper>
);

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export default Error;

import { getLanguage } from "../utils/i18n-utils";

export const DocumentTypes = [
  { c: 'passport', en: 'Passport', es: 'Pasaporte' },
  { c: 'id', en: 'ID Card', es: 'DNI' },
];

export const getDocumentTypes = (language: 'en' | 'es' | string ) =>
  DocumentTypes.map((doc) => ({ value: doc.c, label: doc[language as 'c' | 'en' | 'es'] }));

export const parseDocumentType = (code: string) => {
  const language = getLanguage();
  const docType = DocumentTypes.find((doc) => doc.c === code);
  if (!docType) {
    console.log(`Document type with code '${code}' has not been found`);
    return '';
  }
  return docType[language as 'c' | 'en' | 'es'];
};

import React from 'react';
import { PageWrapper } from '../../template/main-template/main-templateStyles';
import { TopBar } from '../../organisms/topbar/topbar';
import { Header } from 'grommet';
import { Contact } from '../../organisms/contact/contact';
import { Footer } from '../../organisms/footer/footer';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <PageWrapper>
      <TopBar />
      <MessageContainer>
        <Message>{t('notFoundText')}</Message>
      </MessageContainer>
      <Header />
      <Contact />
      <Footer />
    </PageWrapper>
  );
};

export default NotFound;

const MessageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 400px);
  padding: 15px;
  min-height: 300px;

  @media (min-width: 768px) {
    padding: 30px;
    height: calc(100vh - 608px);
    min-height: 500px;
  }
`;

const Message = styled.h2`
  font-family: Merriweather, serif;
  font-size: 1.8rem;
  letter-spacing: 0.1rem;
  text-align: center;
  font-weight: bold;
  line-height: 1.5;
  padding-top: 40px;

  @media (min-width: 768px) {
    padding-top: 80px;
    width: 700px;
    margin: 0 auto;
  }
`;

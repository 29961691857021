import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ContactForm from '../../ContactForm/ContactForm';
import { CollageWrapper, ContactButton, ContactCollage, Label, Wrapper } from './contactStyles';

interface ContactProps {
  onLoad?: () => void;
}

export const Contact = forwardRef<HTMLDivElement, ContactProps>(({ onLoad }, ref) => {
  const [formVisible, setFormVisible] = useState(false);
  const { t } = useTranslation();
  const localRef = React.useRef<HTMLDivElement>(null);

  useImperativeHandle(ref, () => localRef.current!);

  React.useEffect(() => {
    if (onLoad) {
      onLoad();
    }
  }, [onLoad]);

  return (
    <Wrapper ref={localRef} id='CONTACT'>
      {!formVisible ? (
        <CollageWrapper>
          <Label>{t('landing.contact.title')}</Label>
          <ContactButton
            id='Contact_sendUsAnEmail_Button'
            onClick={() => {
              setFormVisible(!formVisible);
            }}
          >
            {t('landing.contact.button')}
          </ContactButton>
          <ContactCollage />
        </CollageWrapper>
      ) : (
        <ContactForm />
      )}
    </Wrapper>
  );
});

import styled from "styled-components";
import { device } from "../../../utils/use-device-type";

export const StepFormWrapper = styled.div<{ $summary?: boolean }>`
  background-color: ${(props) =>
    props.$summary ? "#fbfbfb" : props.theme.landing.colors.primary};
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 1px solid
    ${(props) =>
      props.$summary ? props.theme.landing.colors.primary : "transparent"};
  outline: none;
  box-shadow: 5px 5px 10px 1px #ccc;
  padding: 10px;
  width: 90%;

  > h1 {
    padding: 10px 10px 0 10px;
    font-size: 1.2rem;
    margin: 20px auto;
    max-width: 80%;
  }

  @media ${device.tablet} {
    width: ${(props) => (props.$summary ? "95%" : "100%")};
    max-width: 600px;
  }
  @media ${device.laptop} {
    max-width: 600px;
    min-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 30px;
  }
`;

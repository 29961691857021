import { useState, useEffect } from 'react';

export const size = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 412,
  splitScreen: 600,
  tablet: 768,
  laptop: 1024,
  laptopL: 1440,
  desktop: 2560,
  retinaM: 540,
  retinaL: 950,
  fold: 280,
};

export const useDeviceType = () => {
  const mediaQuery = `(max-width:${size.laptop}px)`;
  const match = () => {
    if (!window.matchMedia) {
      return false;
    }
    return window.matchMedia(mediaQuery).matches;
  };

  const [isMobile, setIsMobile] = useState(match);

  useEffect(() => {
    const handler = () => setIsMobile(match);
    window.addEventListener('resize', handler);
    return () => window.removeEventListener('resize', handler);
  });
  return isMobile ? 'mobile' : 'desktop';
};

export const device = {
  mobileS: `(min-width: ${size.mobileS}px)`,
  mobileM: `(min-width: ${size.mobileM}px)`,
  mobileL: `(min-width: ${size.mobileL}px)`,
  splitScreen: `(min-width: ${size.splitScreen}px)`,
  tablet: `(min-width: ${size.tablet}px)`,
  laptop: `(min-width: ${size.laptop}px)`,
  laptopL: `(min-width: ${size.laptopL}px)`,
  desktop: `(min-width: ${size.desktop}px)`,
  desktopL: `(min-width: ${size.desktop}px)`,
  retina: `(min-device-pixel-ratio: 2),(min-resolution: 264dpi),(min-resolution: 2dppx)`,
  retinaM: `(min-width: ${size.retinaM}px)`,
  retinaL: `(min-width: ${size.retinaL}px)`,
  fold: `(max-width: ${size.fold}px)`,
  landscape: `only screen and (orientation:landscape) and (-webkit-min-device-pixel-ratio: 1.25),
    only screen and (orientation:landscape) and ( min--moz-device-pixel-ratio: 1.25),
    only screen and (orientation:landscape) and ( -o-min-device-pixel-ratio: 1.25/1),
    only screen and (orientation:landscape) and ( min-device-pixel-ratio: 1.25),
    only screen and (orientation:landscape) and ( min-resolution: 200dpi),
    only screen and (orientation:landscape) and ( min-resolution: 1.25dppx)`,
  iPadPro: `only screen 
    and (min-device-width: 1024px) 
    and (max-device-width: 1366px) 
    and (-webkit-min-device-pixel-ratio: 2) `,
};

import { InfoPage } from "../../template/main-template/main-templateStyles";
import { MainTemplate } from "../../template/main-template/main-template";

export const PrivacyPolicy = () => (
  <MainTemplate>
    <InfoPage>
      <h1>WEBSITE PRIVACY POLICY</h1>
      <h2>I. BASIC INFORMATION</h2>
      <p>
        <strong>Privacy and Data Protection Policy</strong>
      </p>
      <p>
        Respecting the provisions of current legislation,
        www.oncampusabroad.com, (hereinafter also Website) undertakes to adopt
        the necessary technical and organizational measures, according to the
        level of security appropriate to the risk of the data collected.
      </p>
      <p>Laws incorporated in this privacy policy.</p>
      <p>
        This privacy policy is adapted to the Spanish and European regulations
        in force regarding the protection of personal data on the Internet.
        Specifically, the same respects the following rules:
      </p>
      <ul>
        <li>
          Regulation (EU) 2016/679 of the European Parliament and of the Council
          of 27 April 2016 on the protection of natural persons with regard to
          the processing of personal data and on the free movement of such data
          (GDPR).
        </li>
        <li>
          The Organic Law 3/2018, of December 5, 2018, on the Protection of
          Personal Data and guarantee of digital rights (LOPD-GDD).
        </li>
        <li>
          Royal Decree 1720/2007, of December 21, which approves the Regulation
          implementing Organic Law 15/1999, of December 13, on the Protection of
          Personal Data (RDLOPD).
        </li>
        <li>
          Law 34/2002, of July 11, 2002, on Information Society Services and
          Electronic Commerce (LSSI-CE).
        </li>
      </ul>
      <p>
        <strong>
          Identity of the person responsible for the processing of personal
          data.
        </strong>
      </p>
      <p>
        <strong>- Responsible:</strong>
      </p>
      <p>
        OnCampus Abroad S.L.
        <br />
        Rúa República de El Salvador 8, 5º, 15701 Santiago de Compostela (A
        Coruña) <br />
        C.I.F: B10795565
        <br />
        Tlf: +34 881 050 054
        <br />
        Contact mail: info@oncampusabroad.com
      </p>
      <p>-Category of data</p>
      <p>
        Contact and identification data, and other data that you provide us.
      </p>
      <p>-Purpose</p>
      <p>
        The purpose for which the data is collected will be that of each
        specific case, but will always be aimed at managing the service provided
        by OnCampus Abroad S.L., the customer within the framework of the
        business relationship that binds them.
      </p>
      <p>-Legitimation</p>
      <p>Consent of the interested party.</p>
      <p>-Recipients</p>
      <p>
        No data is transferred to third parties, nor is it transferred
        internationally.
      </p>
      <p>-Rights</p>
      <p>
        You can exercise your right of access, rectification, deletion,
        portability and limitation of treatment before the data controller. You
        may also revoke your consent at any time by contacting OnCampus Abroad
        S.L., with address at Rúa República de El Salvador 8, 5º, 15701 Santiago
        de Compostela (A Coruña).
      </p>
      <p>-Procedence</p>
      <p>We only process the personal data that you provide us with.</p>
      <p>-Additional information</p>
      <p>You can find more information below:</p>
      <h2>II. DETAILED INFORMATION</h2>
      <p>
        This privacy policy describes how OnCampus Abroad S.L., will treat the
        personal data that you provide to us, and that we may collect from you.
      </p>
      <p>Personal data that we process:</p>
      <p>
        We process personal data of an identifying and professional nature that
        you provide us
      </p>
      <p>with through the website www.oncampusabroad.com</p>
      <p>
        We may process the following categories of personal data provided by
        you:
      </p>
      <ol>
        <li>
          Data of an identifying nature, such as name, surname, telephone
          number, email address, zip code, gender, and date of birth.
        </li>
        <li>How and on what basis we process your personal data.</li>
      </ol>
      <p>
        Your personal data will be processed for the purposes and on the
        legitimate bases indicated below:
      </p>
      <p>
        The purpose for which the data is collected will be that of each
        specific case, but will always aim to manage the service provided by
        OnCampus Abroad S.L., to the customer within the framework of the
        commercial relationship that binds them, send you information and
        advertising about offers and promotions, provided that you have given
        express consent in this regard.
      </p>
      <h2>III. YOUR RIGHTS</h2>
      <p>
        You have the right to receive confirmation as to whether or not your
        personal data is being processed and, if applicable, to request access
        to the personal data and to certain information about the processing
        (purposes, categories of data processed and recipients, among other
        aspects) (RIGHT OF ACCESS).
      </p>
      <p>
        You also have the right to request the rectification of inaccurate data
        (RIGHT OF RECTIFICATION), as well as their deletion when, among other
        reasons, they are no longer necessary for the purposes for which they
        were collected (RIGHT OF DELETION).
      </p>
      <p>
        In certain circumstances (for example, in the event that the data
        subject contests the accuracy of your data, while the accuracy of the
        data is being verified, you may request that the processing of your data
        be limited, while the accuracy of the data is being verified), you may
        request that the processing of your personal data be limited, being
        processed only for the exercise or defense of claims (RIGHT TO LIMIT THE
        PROCESSING).
      </p>
      <p>
        Finally, you have the possibility to exercise your right to data
        portability, i.e. to receive personal data in a structured, commonly
        used and machine-readable format, and to transmit them to another data
        controller without being prevented from doing so by the data controller
        to whom you have provided them, in the cases legally provided for this
        purpose (RIGHT TO DATA PORTABILITY).
      </p>
      <p>
        You may exercise your rights or revoke your consent provided in this
        Privacy Policy at any time by contacting OnCampus Abroad S.L., with
        registered office at Rúa República de El Salvador 8, 5º, 15701 Santiago
        de Compostela (A Coruña).
      </p>
      <p>
        You will also have the right to file a complaint with the Spanish Data
        Protection Agency, especially when you have not received satisfaction in
        the exercise of your rights.
      </p>
      <h2>IV. RECIPIENTS OF YOUR PERSONAL DATA</h2>
      <p>
        We do not transfer your personal data to any third party, nor do we
        transfer it internationally.
      </p>
      <h2>V. RETENTION OF YOUR PERSONAL DATA</h2>
      <p>
        We will retain your personal data until you revoke your consent for as
        long as necessary to comply with any legal obligation arising from the
        processing of your personal data. After this period, your data will be
        deleted.
      </p>
    </InfoPage>
  </MainTemplate>
);

import { NavigationPathDefinition } from '../../molecules/navigation-menu/navigation-menu';

export const LAPTOP_HEADER_NAVIGATION: NavigationPathDefinition[] = [
  { id: 'WHY-ONCAMPUS', path: '/why-oncampus', scrollTo: true, name: 'landing.topBar.menu-item-1' },
  { id: 'FAQ', path: '/faq', scrollTo: true, name: 'landing.topBar.menu-item-2' },
  { id: "BLOG", path: "https://blog.oncampusabroad.com/", newWindow: true, name: 'landing.topBar.menu-item-4'},
  { id: 'CONTACT', path: '/contact', scrollTo: true, name: 'landing.topBar.menu-item-3' },
];

export const MOBILE_HEADER_NAVIGATION: NavigationPathDefinition[] = [
  { id: 'WHY-ONCAMPUS', path: '/why-oncampus', scrollTo: true },
  { id: 'FAQ', path: '/faq', scrollTo: true },
  { id: 'CONTACT', path: '/contact' },
  { id: 'LEGAL-NOTICE', path: '/legal-notice', newWindow: true },
  { id: 'PRIVACY-POLICY', path: '/privacy-policy', newWindow: true },
];

export const FOOTER_NAVIGATION: NavigationPathDefinition[] = [
  {
    id: 'LEGAL-NOTICE',
    path: '/legal-notice',
    newWindow: true,
    name: 'landing.footer.legal-notice',
  },
  {
    id: 'PRIVACY-POLICY',
    path: '/privacy-policy',
    newWindow: true,
    name: 'landing.footer.privacy-policy',
  },
  {
    id: 'COOKIES-POLICY',
    path: '/cookies-policy',
    newWindow: true,
    name: 'landing.footer.cookies-policy',
  },
];

import React, { useState } from 'react';
import { TextInput } from '../../atoms/text-input/text-input';
import { PrefixPhoneInput } from '../../atoms/phone-input/phone-input';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import StepWrapper from '../stepWrapper';
import {
  OrderFlowTabs,
  setExtraPersonalDetails,
  setTab
} from '../../../redux/orderFlow/orderFlowSlice';
import { NewFlowBox } from './PersonalDetails';
import {
  validateEmail,
  validatePhoneNumber
} from '../../../utils/form-validator';

const ExtraPersonalDetails = () => {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState<string>(
    useAppSelector(state => state.orderFlow.email) || ''
  );
  const [phoneNumber, setPhoneNumber] = useState<string>(
    useAppSelector(state => state.orderFlow.phoneNumber) || ''
  );
  const [emailError, setEmailError] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const validate = () => {
    const emailIsValid = validateEmail(email);
    const phoneNumberIsValid = validatePhoneNumber(phoneNumber);
    setEmailError(!emailIsValid);
    setPhoneNumberError(!phoneNumberIsValid);
    return emailIsValid && phoneNumberIsValid;
  };
  const handleContinue = () => {
    const isValid = validate();
    if (isValid) {
      dispatch(
        setExtraPersonalDetails({
          email,
          phoneNumber
        })
      );
      dispatch(setTab(OrderFlowTabs.PersonalDetailsConfirmation));
    }
  };
  const handleBack = () => {
    dispatch(setTab(OrderFlowTabs.IdentificationDetails));
  };

  return (
    <StepWrapper
      next={{ f: handleContinue, label: 'NEXT' }}
      back={{ f: handleBack, label: 'BACK' }}
    >
      <NewFlowBox>
        <PrefixPhoneInput
          field={{
            id: 'phoneNumber',
            type: 'phone',
            mandatory: true
          }}
          onChange={setPhoneNumber}
          value={phoneNumber}
          warning={phoneNumberError}
        />
        <TextInput
          field={{ id: 'email', type: 'email', mandatory: true }}
          onChange={setEmail}
          value={email}
          warning={emailError}
        />
      </NewFlowBox>
    </StepWrapper>
  );
};

export default ExtraPersonalDetails;

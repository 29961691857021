import styled from 'styled-components';
import { SessionManager } from '../../../utils/session-manager';
import { Button } from '../../template/main-template/main-templateStyles';
import { useTranslation } from 'react-i18next';

export const NextBackButtons = (props: {
  next: { f: () => void; label: string; disabled?: boolean | false };
  back?: { f: () => void; label: string } | null;
}) => {
  const { t } = useTranslation();
  return (
    <ButtonsWrapper>
      {props.back && (
        <Button
          $secondary
          id='Back_NextBackButtons_Button'
          onClick={() => {
            SessionManager.setButtonId('Back_NextBackButtons_Button');
            props.back?.f();
          }}
        >
          {t(props.back.label)}
        </Button>
      )}
      <Button
        disabled={props.next.disabled}
        id='Next_NextBackButtons_Button'
        onClick={() => {
          SessionManager.setButtonId('Next_NextBackButtons_Button');
          props.next?.f();
        }}
      >
        {t(props.next.label)}
      </Button>
    </ButtonsWrapper>
  );
};

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 5px;
  justify-content: center;
  > button {
    min-width: 0;
    width: 100%;
  }
`;

import styled from 'styled-components';
import { MEDIA_URL } from '../../template/main-template/main-templateStyles';
import { device } from '../../../utils/use-device-type';

export const Title = styled.h2`
  margin: 15px auto;
  max-width: 300px;
  @media ${device.laptop} {
    max-width: 100%;
  }
`;
export const Wrapper = styled.div`
  background: #f4ede8;
  padding-top: 90px;
  text-align: center;
  position: relative;
  &::before {
    content: ' ';
    height: 150px;
    width: 150px;
    position: absolute;
    top: -50px;
    left: 0;
    right: 0;
    background: url('${MEDIA_URL}/oncampus_FAQ.png') center no-repeat;
    background-size: contain;
    margin: 0 auto;
  }
  @media (min-width: 991px) {
    &::before {
      top: -40px;
      left: 80px;
      right: auto;
      height: 200px;
      width: 130px;
      margin: 0;
    }
    &::after {
      content: ' ';
      height: 400px;
      width: 150px;
      position: absolute;
      top: 70px;
      left: -50px;
      background: url('${MEDIA_URL}/side_plane.png') center no-repeat;
      background-size: contain;
    }
  }
`;
export const ExtraWrapper = styled.div`
  background: #f4ede8;
  padding-top: 90px;
  text-align: center;
  position: relative;
  &::before {
    content: ' ';
    height: 150px;
    width: 150px;
    position: absolute;
    top: -50px;
    left: 0;
    right: 0;
    background-size: contain;
    margin: 0 auto;
  }
  @media (min-width: 991px) {
    &::before {
      top: -40px;
      left: 80px;
      right: auto;
      height: 200px;
      width: 130px;
      margin: 0;
    }
    &::after {
      content: ' ';
      height: 400px;
      width: 150px;
      position: absolute;
      top: 70px;
      left: -50px;
      background: url('${MEDIA_URL}/side_plane.png') center no-repeat;
      background-size: contain;
    }
  }
`;

export const Questions = styled.div`
  margin: 0 auto;
  display: inline-block;
  padding-bottom: 70px;
  background: url('${MEDIA_URL}/oncampus_deco_arrow.png') no-repeat center bottom;
  background-size: 80px;
  width: 80%;
  @media (min-width: 991px) {
    background-size: 120px;
    padding-bottom: 120px;
    max-width: 800px;
    width: 60%;
  }
`;
export const ExtraQuestions = styled.div`
  margin: 0 auto;
  display: inline-block;
  padding-bottom: 70px;
  background-size: 80px;
  width: 80%;
  @media (min-width: 991px) {
    background-size: 120px;
    padding-bottom: 120px;
    max-width: 800px;
    width: 60%;
  }
`;

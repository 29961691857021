import { FirehoseClient, PutRecordCommand } from "@aws-sdk/client-firehose";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import { SessionManager } from "../utils/session-manager";

const awsConfigParams: {
  region: string;
  identityPoolId: string;
  deliveryStream: string;
} = {
  //@ts-ignore
  region: window.OPSEEKER_CONFIG.REGION,
  //@ts-ignore
  identityPoolId: window.OPSEEKER_CONFIG.IDENTITY_POOL_ID,
  //@ts-ignore
  deliveryStream: window.OPSEEKER_CONFIG.DELIVERY_STREAM_NAME,
};

const client = new FirehoseClient({
  region: awsConfigParams.region,
  credentials: fromCognitoIdentityPool({
    identityPoolId: awsConfigParams.identityPoolId,
    clientConfig: { region: awsConfigParams.region },
  }),
});

interface SessionInfo {
  userId: string | null;
  sessionDuration: number | null;
  pageVisitsCount: number;
  isMobile: boolean;
  isComputer: boolean;
  newSessionPercentage: number;
  userFlow: string[];
  userOrigin: string | null;
  pageLoadTime: number;
  searchCount: number;
  isReturningUser: boolean;
  visitFrequency: number;
  query: string;
  userAgent: string;
  buttonId: string;
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
}

export const sendLog = async (tab: any, sessionInfo: SessionInfo) => {
  const bounceRate = SessionManager.getBounceRate();

  const getDeviceType = () => {
    if (sessionInfo.isMobile) return "mobile";
    if (sessionInfo.isComputer) return "computer";
  };

  const data = JSON.stringify({
    userUUID: sessionInfo.userId,
    timestamp: new Date(),
    tab: tab,
    //@ts-ignore
    referrer: window.OPSEEKER_CONFIG.REFERRER,
    //@ts-ignore
    environment: window.OPSEEKER_CONFIG.ENVIRONMENT,
    deviceType: getDeviceType(),
    "user-agent": sessionInfo.userAgent,
    time: getCurrentDateTime(),
    query: sessionInfo.query,
    button_id: sessionInfo.buttonId,
    sessionDuration: sessionInfo.sessionDuration,
    pageVisitsCount: sessionInfo.pageVisitsCount,
    bounceRate: bounceRate,
    newSessionPercentage: sessionInfo.newSessionPercentage,
    userFlow: sessionInfo.userFlow,
    userOrigin: sessionInfo.userOrigin,
    pageLoadTime: sessionInfo.pageLoadTime,
    searchCount: sessionInfo.searchCount,
    isReturningUser: sessionInfo.isReturningUser,
    visitFrequency: sessionInfo.visitFrequency,
    utm_source: sessionInfo.utm_source,
    utm_medium: sessionInfo.utm_medium,
    utm_campaign: sessionInfo.utm_campaign,
  });

  const input: any = {
    //@ts-ignore
    DeliveryStreamName: window.OPSEEKER_CONFIG.DELIVERY_STREAM_NAME,
    Record: {
      Data: new TextEncoder().encode(data + "\n"),
    },
  };

  const command = new PutRecordCommand(input);
  const response = await client.send(command);

  return response;
};

export function getCurrentDateTime(): string {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Suma 1 ya que los meses comienzan desde 0
  const day = String(currentDate.getDate()).padStart(2, "0");
  const hours = String(currentDate.getHours()).padStart(2, "0");
  const minutes = String(currentDate.getMinutes()).padStart(2, "0");
  const seconds = String(currentDate.getSeconds()).padStart(2, "0");
  const milliseconds = String(currentDate.getMilliseconds()).padStart(3, "0");
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
}

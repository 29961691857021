import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Ollie from '../../assets/ollie.svg';
import OllieBlinking from '../../assets/ollie-blinking.svg';
import Cross from '../../assets/cross-chatbot.svg';
import { device } from '../../utils/use-device-type';

export const Chatbot: React.FC = () => {
  const [isIframeVisible, setIframeVisible] = useState(() => {
    const savedState = localStorage.getItem('isIframeVisible');
    return savedState === 'true';
  });
  const [isIframeLoaded, setIframeLoaded] = useState(false);

  const Environment = (window as any).OPSEEKER_CONFIG.ENVIRONMENT;
  const iframeSrc =
    Environment === 'staging' || Environment === 'qa'
      ? 'https://beflow.opseeker.com/staging/oncampus-abroad/oli/index.html'
      : 'https://beflow.opseeker.com/oncampus-abroad/oli/index.html';

  useEffect(() => {
    localStorage.setItem('isIframeVisible', isIframeVisible.toString());
  }, [isIframeVisible]);
  const toggleIframe = () => {
    setIframeVisible(!isIframeVisible);
    setIframeLoaded(false);
  };
  const handleIframeLoad = () => {
    setTimeout(() => {
      setIframeLoaded(true);
    }, 500);
  };
  return (
    <ChatbotWrapper onClick={toggleIframe}>
      {!isIframeVisible && (
        <ChatbotSmall>
          <OllieContainer>
            <OllieImage />
          </OllieContainer>
          <Text>I'm Ollie, your personal assistant</Text>
        </ChatbotSmall>
      )}
      {isIframeVisible && (
        <IframeWrapper>
          {isIframeLoaded && <CloseButton onClick={toggleIframe} />}
          <IframeChatbot
            src={iframeSrc}
            onLoad={handleIframeLoad}
          ></IframeChatbot>
        </IframeWrapper>
      )}
    </ChatbotWrapper>
  );
};
const CloseButton = styled.div`
  position: absolute;
  top: 16px;
  right: 17px;
  width: 18px;
  height: 18px;
  background-image: url(${Cross});
  background-size: cover;
  cursor: pointer;
  z-index: 1002;
`;
const IframeChatbot = styled.iframe`
  height: 100%;
  width: 100%;
  z-index: 200;
  @media ${device.tablet} {
    width: 416px;
    height: 570px;
  }
`;
const IframeWrapper = styled.div`
  position: fixed;
  z-index: 1001;
  overflow: hidden;
  border-radius: 20px;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  @media ${device.tablet} {
    bottom: 100px;
    right: 44px;
    height: 570px;
    width: 416px;
  }
`;

const OllieImage = styled.div`
  width: 75px;
  height: 75px;
  background-image: url(${Ollie});
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 100px;
  animation: blink 3s infinite steps(1, end);
  @keyframes blink {
    0%,
    100% {
      background-image: url(${Ollie});
    }
    50% {
      background-image: url(${OllieBlinking});
    }
  }
  @media ${device.tablet} {
    animation: none;
    width: 55px;
    height: 55px;
    background-image: url(${Ollie});
  }
`;
const OllieContainer = styled.div`
  width: 95px;
  height: 95px;
  background: none;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${device.tablet} {
    width: 65px;
    height: 65px;
  }
`;
const ChatbotWrapper = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1000;
`;

const Text = styled.p`
  display: none;
  @media ${device.tablet} {
    margin: 0;
    padding: 0;
    color: rgb(38, 50, 135);
    display: block;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    width: 60%;
    margin-left: 11px;
  }
`;
const ChatbotSmall = styled.div`
  bottom: 100px;
  right: 40px;
  width: 95px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${device.tablet} {
    bottom: 200px;
    right: 44px;
    width: 250px;
    padding: 5px 20px;
    cursor: pointer;
  }
  border-radius: 100px;
  background: #fff;
  position: fixed;

  border: 1px solid #fff;
  :hover {
    background: #283288;
    color: #fff;
    animation: none;
  }
  :hover ${OllieImage} {
    background-image: url(${OllieBlinking});
    width: 55px;
    height: 55px;
  }
  :hover ${OllieContainer} {
    background-color: #fff;
  }
  :hover ${Text} {
    color: #fff;
  }
  animation: pump 2s infinite;
  @keyframes pump {
    0%,
    100% {
      transform: scale(1.05);
    }
    50% {
      transform: scale(1);
    }
  }
`;

import { Box, Text, Heading, CheckBox, Anchor } from 'grommet';
import { Money } from 'grommet-icons';
import * as React from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetOrderDetails } from '../../../api/use-get-order-details';
import { useGetPaymentUrl } from '../../../api/use-get-payment-url';
import { processOrderDetailText } from '../../../utils/text-processor';
import { HiddenForm } from './hidden-form';
import { useTranslation } from 'react-i18next';

export const PriceTagPresenter = () => {
  const { t } = useTranslation();
  const { orderId } = useParams();
  const orderDetails = useGetOrderDetails(orderId);
  const paymentUrl: any = useGetPaymentUrl(orderId);
  const [checked, setChecked] = useState(false);

  if (!orderDetails) return <React.Fragment />;
  return (
    <Box pad='small' gap='small' width='medium' align='center'>
      <Heading level={2}>{processOrderDetailText(t('price-tag-title'), orderDetails)}</Heading>
      <Box direction='row' gap='medium' align='center'>
        <Money size='large' />
        <Heading level={1}>
          {orderDetails.amount} {orderDetails.currency}
        </Heading>
      </Box>
      <Text>{processOrderDetailText(t('price-tag-subtitle'), orderDetails)}</Text>
      <CheckBox
        checked={checked}
        label={<Anchor href='#' label={t('accept-terms-and-conditions')} />}
        onChange={(event) => setChecked(event.target.checked)}
      />
      <Box gap='xsmall' direction='row' justify='center'>
        <HiddenForm payment={paymentUrl} />
      </Box>
    </Box>
  );
};

export const getRedirectionUrl = (url: string) => {
  //@ts-ignore
  if (window.OPSEEKER_CONFIG.LOCAL) {
    return url + '&isLocal=true';
  }
  return url;
};

import { HiddenForm } from "../../organisms/pricetag/hidden-form";
import { useAppSelector } from "../../../redux/hooks";

const Payment = () => {
  const paymentData = useAppSelector(
    (state) => state.orderApi.orderDetails.response.data
  );
  const paymentUrl = useAppSelector(
    (state) => state.orderApi.orderDetails.response.paymentUrl
  );
  return (
    <div>
      <HiddenForm payment={{ url: paymentUrl, data: paymentData }} />
    </div>
  );
};

export default Payment;

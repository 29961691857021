import styled from "styled-components";
import { MEDIA_URL } from "../../template/main-template/main-templateStyles";
import { device } from "../../../utils/use-device-type";

const COLORS = ["#F9A0A2", "#F9E6B2", "#253287"];

export const Container = styled.div`
  background: ${(props) => props.theme.landing.colors.primary};
  > h2 {
    color: #ffffff;
    margin: 0 auto 50px auto;
  }
  > h2 .accent {
    color: ${(props) => props.theme.landing.colors.third};
  }
  > p {
    font-family: "Merriweather", serif;
    color: #ffffff;
    margin: 40px auto;
    font-weight: bold;
    font-size: 1.5rem;
    max-width: 100%;
    line-height: 120%;
    margin: 10px auto;
  }
  text-align: center;
  padding: 50px 20px 60px 20px;
  position: relative;

  &::after {
    content: " ";
    display: block;
    width: 100%;
    height: 300px;
    position: absolute;
    top: -100px;
    left: 0;
    right: 0;
    background: url("${MEDIA_URL}/mobile/students.webp") no-repeat;
    background-size: contain;
    margin: 0 auto;
    transform: scaleX(-1);
  }

  @media (min-width: 300px) {
    &::after {
      width: 300px;
    }
  }
  @media (min-width: 768px) {
    > h2 {
      max-width: 500px;
    }
  }
  @media (min-width: 991px) {
    padding: 50px 50px 90px 50px;
    &::after {
      background-image: url("${MEDIA_URL}/students.webp");
      width: 500px;
      height: 500px;
      top: -170px;
    }
    > h2 {
      max-width: 800px;
      margin: 50px auto 70px auto;
    }
  }
`;

export const Packs = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  background: url("${MEDIA_URL}/arrow.png") bottom center no-repeat;
  background-size: 40px;
  padding-bottom: 50px;

  @media (min-width: 600px) {
    max-width: 80%;
  }

  @media (min-width: 991px) {
    max-width: 800px;
    background-size: 50px;
    padding-bottom: 40px;
  }
`;

export const Wrapper = styled.div`
  margin: 0 auto;
  text-align: center;
  @media (min-width: 991px) {
    max-width: 980px;
  }
`;
export const DownloadLinks = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin: 30px auto;
  flex-direction: column;

  @media ${device.laptop} {
    flex-wrap: nowrap;
    flex-direction: row;
    padding-left: 0;
  }
`;

export const Doc = styled.div`
  background-size: 45px;
  background-position: left;
  background-size: contain;
  text-align: left;
  width: 100%;
  display: flex;
  gap: 10px;
  padding: 10px 15px;
  border-radius: 8px;
  background: #acc4ff;
  align-items: center;
  > p {
    max-width: 100%;
  }
  @media ${device.mobileS} {
    width: 290px;
  }
`;

export const Icon = styled.div<{ fileName: string; sequence: number }>`
  background: url("${MEDIA_URL}/icons/${(props) => props.fileName}.svg")
    ${(props) => COLORS[props.sequence]} center;
  background-size: 30px;
  background-repeat: no-repeat;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  min-width: 50px;
  @media (min-width: 991px) {
    height: 60px;
    width: 60px;
    min-width: 60px;
    background-size: 30px;
  }
`;

export const DownloadContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 0;
  @media ${device.tablet} {
    height: 80px;
  }
  @media ${device.laptop} {
    height: 80px;
  }
`;

export const TextLink = styled.a`
  display: inline-block;
  color: ${(props) => props.theme.landing.colors.textColor};
  text-decoration: underline;
  background: url("${MEDIA_URL}/icons/download.svg") no-repeat;
  background-size: 15px;
  background-position: 0 3px;
  padding-left: 18px;

  &:link,
  &:visited,
  &:hover,
  &:active {
    color: ${(props) => props.theme.landing.colors.primary};
  }
`;
export const DownloadsWrapper = styled.div`
  background: ${(props) => props.theme.landing.colors.lightBlue};
  padding: 60px 20px;
`;

export const Pack = styled.div<{ type: string }>`
display:flex;
justify-content: center;
flex-direction:column-reverse;
>div h3{
  border-bottom:8px solid ${(props) =>
    props.type === "sports"
      ? props.theme.landing.colors.lightBlue
      : props.theme.landing.colors.yellow};
  color:#ffffff;font-size:1.2rem;text-align:left;padding:5px 0;
}

>div p{color:#ffffff;text-align:left;font-size: .9rem;line-height: 130%;}

@media (min-width: 600px) {
  flex-direction:row;
  margin-left:-70px;
}
@media (min-width: 991px) {
  flex-direction: ${(props) =>
    props.type === "sports" ? "row" : "row-reverse"};
  >h3{font-size:2rem;}
  >div p{font-size: 1rem;line-height: 110%;}
`;

export const Img = styled.div<{ type: string }>`
  background: url("${MEDIA_URL}/pack_${(props) => props.type}.webp");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  @media ${device.tablet} {
    margin: 0;
  }
  @media (min-width: 991px) {
    height: 200px;
    width: 200px;
  }
`;

export const Title = styled.p`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.8rem;
  line-height: 110%;
  margin-bottom: 8px;
  margin-top: -2px;
`;

import { API } from "aws-amplify"
import { useEffect, useState } from "react"



export const useGetLambdaVersion = () => {
    const [version, setVersion] = useState('')
    useEffect(() => {
        API.get('oncampus-api', '', {
            response: true,
            queryStringParameters: {
                action: 'version',
            }
        })
            .then((response: any) => setVersion(response.data))
            .catch((error: any) => console.log(error))
    }, [])
    return version
}
import React from 'react';
import { StepFormWrapper } from '../../molecules/form-tab-presenter/form-tabStyles';
import Error from '../../Lottie/error';
import { useTranslation } from 'react-i18next';

interface ErrorTabProps {
  error: string;
}

const ErrorTab: React.FC<ErrorTabProps> = ({ error }) => {
  const { t } = useTranslation();
  return (
    <StepFormWrapper $summary>
      <Error text={t(`${error}`)} />
    </StepFormWrapper>
  );
};

export default ErrorTab;

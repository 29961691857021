import { InfoPage } from "../../template/main-template/main-templateStyles";
import { MainTemplate } from "../../template/main-template/main-template";

export const CookiesPolicy = () => (
  <MainTemplate>
    <InfoPage>
      <h1>INFORMATION ABOUT COOKIES</h1>
      <p>
        In accordance with Law 34/2002, of July 11, 2002, on information society
        services and electronic commerce (LSSI), in relation to Regulation (EU)
        2016/679 of the European Parliament and of the Council of 27 April 2016,
        General Data Protection Regulation (GDPR) and Organic Law 3/2018, of
        December 5, 2018, on Data Protection and Guarantee of Digital Rights
        (LOPDGDD), it is mandatory to obtain the express consent of the user of
        all websites that use dispensable cookies, before the user navigates
        through them.
      </p>

      <h2>WHAT ARE COOKIES?</h2>
      <p>
        Access to this website may involve the use of cookies. Cookies and other
        similar technologies such as local shared objects, flash cookies or
        pixels, are tools used by Web servers to store and retrieve information
        about their visitors, as well as to provide a correct functioning of the
        site.
      </p>
      <p>
        The use of these devices allows the Web server to remember some data
        concerning the user, such as your preferences for viewing the pages of
        that server, name and password, products that most interest you, etc..
      </p>

      <h2>COOKIES AFFECTED BY THE REGULATION AND EXCEPTED COOKIES</h2>
      <p>
        According to the EU directive, the cookies that require the user's
        informed consent are analytics cookies and advertising and affiliation
        cookies, with the exception of technical cookies and those necessary for
        the operation of the website or the provision of services expressly
        requested by the user.
      </p>

      <h2>TYPES OF COOKIES</h2>
      <h3>ACCORDING TO THE PURPOSE</h3>
      <p>
        Technical and functional cookies: are those that allow the user to
        navigate through a website, platform or application and the use of the
        different options or services that exist in it.
      </p>
      <p>
        Analytical Cookies: these are those that allow the person responsible
        for them to monitor and analyze the behavior of the users of the
        websites to which they are linked. The information collected through
        this type of cookies is used to measure the activity of the websites,
        application, or platform and for the elaboration of browsing profiles of
        the users of these sites, applications and platforms, in order to
        introduce improvements based on the analysis of the usage data of the
        users of the service.
      </p>
      <p>
        Advertising Cookies: are those that allow the management, in the most
        effective way possible, of the advertising spaces that, where
        appropriate, the publisher has included on a website, application or
        platform from which it provides the requested service based on criteria
        such as the edited content or the frequency at which the ads are
        displayed. Behavioral advertising cookies: these collect information on
        the user's preferences and personal choices (retargeting) to enable the
        management, in the most effective way possible, of the advertising
        spaces that, where appropriate, the publisher has included on a web
        page, application or platform from which it provides the requested
        service. Social Cookies: are set by social media platforms on the
        Services to allow you to share content with your friends and networks.
        Social media platforms have the ability to track your online activity
        outside of the Services. This may affect the content and messages you
        see on other services you visit. Affiliate cookies: allow tracking of
        visits coming from other websites, with which the website establishes an
        affiliation contract (affiliate companies).
      </p>
      <p>
        Security cookies: they store encrypted information to prevent the data
        stored in them from being vulnerable to malicious attacks by third
        parties.
      </p>

      <h3>ACCORDING TO OWNERSHIP</h3>
      <p>
        Own Cookies: those that are sent to the user's terminal equipment from a
        computer or domain managed by the editor itself and from which the
        service requested by the user is provided.
      </p>
      <p>
        Third-party cookies: those that are sent to the user's terminal
        equipment from a computer or domain that is not managed by the editor,
        but by another entity that processes the data obtained through cookies.
      </p>

      <h3>ACCORDING TO THE STORAGE TIME</h3>
      <p>
        Session cookies: these are a type of cookie designed to collect and
        store data while the user accesses a web page. Persistent cookies: these
        are a type of cookie in which the data remains stored in the terminal
        and can be accessed and processed for a period defined by the party
        responsible for the cookie, which can range from a few minutes to
        several years.
      </p>

      <h2>PROCESSING OF PERSONAL DATA</h2>
      <p>
        OnCampus Abroad S.L. is responsible for the processing of the personal
        data of the Data Subject and informs you that these data will be
        processed in accordance with the provisions of Regulation (EU) 2016/679
        of 27 April 2016 (GDPR), for which the following processing information
        is provided to you:
      </p>

      <p>
        Purposes of processing: as specified in the section on cookies used on
        this website.
      </p>
      <p>
        Legitimation of the processing: by consent of the data subject (art. 6.1
        GDPR).
      </p>
      <p>
        Data retention criteria: as specified in the section on cookies used on
        the website.
      </p>
      <p>
        Communication of data: data will not be communicated to third parties,
        except in cookies owned by third parties or by legal obligation.
      </p>
      <p>
        Rights of the data subject: Right to withdraw consent at any time. Right
        of access, rectification, portability and deletion of their data, and to
        limit or oppose its processing. Right to file a complaint with the
        Supervisory Authority (www.aepd.es) if he/she considers that the
        processing does not comply with the regulations in force.
      </p>
      <p>
        Contact details to exercise your rights: <br />
        OnCampus Abroad S.L., C.I.F: B10795565, Rúa República de El Salvador 8,
        5º, 15701 Santiago de Compostela (A Coruña). Contact mail:
        info@oncampusabroad.com{' '}
      </p>

      <h2>COOKIES USED ON THIS WEBSITE:</h2>
      <h3>COOKIES CONTROLLED BY THE PUBLISHER:</h3>
      <p>Technical and functional</p>
      <table>
        <tr>
          <td>Ownership</td>
          <td>Cookie</td>
          <td>Purpose</td>
          <td>Duration</td>
        </tr>
        <tr>
          <td>www.oncampusabroad.com</td>
          <td>cookieconsent_</td>
          <td>
            Cookie necessary for the use of the options and services of the
            website.
          </td>
          <td>1 year</td>
        </tr>
      </table>

      <h3>THIRD PARTY COOKIES:</h3>
      <p>
        Third-party services are beyond the control of the publisher. Suppliers
        can modify their conditions of service, purpose and use of cookies, etc.
        at any time.
      </p>
      <table>
        <tr>
          <td>Ownership</td>
          <td>Cookie</td>
          <td>Purpose</td>
          <td>Duration</td>
        </tr>
        <tr>
          <td>google.com</td>
          <td>_gat</td>
          <td>
            Used to send data to Google Analytics about the visitor's device and
            behavior. Tracks the visitor across devices and marketing channels.
          </td>
          <td>1 day</td>
        </tr>
        <tr>
          <td>google.com</td>
          <td>_gid</td>
          <td>
            Used to send data to Google Analytics about the visitor's device and
            behavior. Tracks the visitor across devices and marketing channels.
          </td>
          <td>1 day</td>
        </tr>
        <tr>
          <td>google.com</td>
          <td>_GRECAPTCHA</td>
          <td>
            reCAPTCHA sets a necessary cookie (_GRECAPTCHA) when executed for
            the purpose of providing its risk analysis.
          </td>
          <td>1 day</td>
        </tr>
      </table>

      <h2>External providers of this website:</h2>
      <table>
        <tr>
          <td>Editor</td>
          <td>Privacy Policy</td>
        </tr>
        <tr>
          <td>Google</td>
          <td>AnalyticsGoogle</td>
        </tr>
        <tr>
          <td>Google Analytics</td>
          <td>https://privacy.google.com/take-control.html</td>
        </tr>
        <tr>
          <td>Google</td>
          <td>https://privacy.google.com/take-control.html</td>
        </tr>
      </table>

      <h3>COOKIES SETTINGS PANEL</h3>
      <p>
        From this panel you can configure the cookies that the website can
        install on your browser, except for technical or functional cookies that
        are necessary for navigation and use of the different options or
        services offered.
      </p>
      <h3>Cookies Panel:</h3>
      <p>HOW TO MANAGE COOKIES FROM THE BROWSER</p>
      <table>
        <tr>
          <td>Delete cookies from the device</td>
          <td>
            Cookies that are already on a device can be deleted by clearing the
            browser history, which deletes cookies from all websites visited.
            <br />
            However, some of the saved information (e.g. login data or website
            preferences) may also be lost.
          </td>
        </tr>
        <tr>
          <td>Manage site-specific cookies</td>
          <td>
            To have more precise control over site-specific cookies, users can
            adjust their privacy and cookie settings in their browser.
          </td>
        </tr>
        <tr>
          <td>Block Cookies</td>
          <td>
            Although most modern browsers can be configured to prevent cookies
            from being installed on your devices, this may require you to
            manually adjust certain preferences each time you visit a site or
            page. In addition, some services and features may not function
            properly (e.g., profile logins).
          </td>
        </tr>
      </table>
      <h3>HOW TO DELETE COOKIES FROM THE MOST COMMON BROWSERS</h3>
      <table>
        <tr>
          <td>Chrome</td>
          <td> https://support.google.com/chrome/answer/95647?hl=en </td>
        </tr>

        <tr>
          <td>Edge</td>
          <td>
            {' '}
            https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09{' '}
          </td>
        </tr>

        <tr>
          <td>Explorer</td>
          <td>
            {' '}
            https://support.microsoft.com/en-us/topic/how-to-delete-cookie-files-in-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc{' '}
          </td>
        </tr>

        <tr>
          <td>Firefox </td>
          <td> https://www.mozilla.org/en-US/privacy/websites/#cookies </td>
        </tr>

        <tr>
          <td>Safari</td>
          <td> https://support.apple.com/es-es/guide/safari/sfri11471/mac </td>
        </tr>

        <tr>
          <td>Opera</td>
          <td>
            {' '}
            https://help.opera.com/en/latest/security-and-privacy/#clearBrowsingData{' '}
          </td>
        </tr>
      </table>
    </InfoPage>
  </MainTemplate>
);

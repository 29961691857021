import { InfoPage } from "../../template/main-template/main-templateStyles";
import { MainTemplate } from "../../template/main-template/main-template";

export const LegalNotice = () => (
  <MainTemplate>
    <InfoPage>
      <h1>LEGAL NOTICE</h1>
      <p>
        <strong>OnCampus Abroad S.L. (ONCAMPUS)</strong> provides access to the
        company website, located at{' '}
        <a
          href='https://www.oncampusabroad.com/'
          rel='noreferrer noopener'
          target='_blank'
          style={{ color: '#000000' }}
        >
          www.oncampusabroad.com
        </a>
        , (the “Website”) subject to your acceptance of this OnCampus Website
        User Agreement (“Agreement”).
      </p>

      <p>
        Company reserves the right to modify this Agreement or/and any type of
        information that it could appear on the website without there being an
        obligation of prior notice or to inform the users of said obligations,
        understanding that the publication of the website is sufficient. We
        encourage you to review this Agreement periodically for any updates or
        changes.
      </p>

      <h2>1. General Terms and Conditions</h2>
      <p>
        This Legal Notice contains the conditions that regulate the use and
        access to the website{' '}
        <a
          href='http://www.oncampusabroad.com/'
          target='_blank'
          rel='noreferrer noopener'
          style={{ color: '#000000' }}
        >
          www.oncampusabroad.com
        </a>
        , (hereinafter site or web), official Internet site, and property of
        OnCampus Abroad S.L., (hereinafter <b>OnCampus</b>), as well as the
        responsibilities derived from its use, in compliance with Law 34/2002,
        of July 11, 2002, on Information Society Services and Electronic
        Commerce (hereinafter LSSI).
      </p>
      <p>
        Users may access the site freely and free of charge. It is understood
        that the access or mere use of the website by the user implies the
        user&#39;s adherence to all the conditions that <b>OnCampus </b> has
        published at that time on the website. By accessing and/or registering
        as a user, the user acknowledges having read and understood these Terms
        and Conditions and accepts them. By accessing this site you agree that{' '}
        <b>OnCampus </b> is not responsible for any consequence, damage or harm
        arising from such access or use of the information on this site or
        access to other materials on the Internet through connections with this
        site.
      </p>
      <p>
        The Users are informed that Oncampus respects the current legislation on
        the protection of personal data, the privacy of users, and the secrecy
        and security of their personal data, adopting the necessary technical
        and organizational measures to prevent the loss, misuse, alteration,
        unauthorized access and theft of the personal data provided.
      </p>

      <h2>2. Title holder and third parties.</h2>
      <ul id='l2'>
        <li data-list-text='-'>
          The TITLE HOLDER of this website and the domains{' '}
          <a
            href='http://www.oncampusabroad.com/'
            target='_blank'
            rel='noreferrer noopener'
            style={{ color: '#000000' }}
          >
            www.oncampusabroad.com
          </a>
          , is <b>OnCampus Abroad S.L </b>
          with C.I.F: B10795565 and address at: C/República de El Salvador, 8 -
          5º, 15701 Santiago de Compostela, A Coruña, telephone: +34 881 050 054
          and contact email:{' '}
          <a
            href='mailto:info@oncampusabroad.com'
            target='_blank'
            rel='noreferrer noopener'
            style={{ color: '#000000' }}
          >
            info@oncampusabroad.com
          </a>
        </li>
        <li data-list-text='-'>
          The MEDIATOR, DISTRIBUTOR AND POLICYHOLDER is :
          <b>OnCampus Abroad S.L. </b>, with CIF: B10795565, and address at:
          C/República de El Salvador, 8 - 5º, 15701 Santiago de Compostela, A
          Coruña.
        </li>
        <li data-list-text='-'>
          The INSURANCE COMPANY is:
          <b>ERGO Seguros de Viaje</b>, Sucursal España with CIF: W0040918E and
          address at: Avda. Isla Graciosa, 1, 28703, San Sebastián de los Reyes,
          Madrid.
        </li>
      </ul>

      <h2>3. Object and Purpose</h2>
      <p>
        The purpose of this website is to inform about the activity and services
        offered and developed by <b>OnCampus </b> in the field of providing
        services to the university community. The name of the product called:{' '}
        <b>OnCampus </b> Abroad, is a travel insurance for students and
        university staff traveling abroad for academic purposes.
      </p>
      <p>
        Clients: Students and University Staff.Area of activity: European
        territory (except United Kingdom, Germany and Spain).
      </p>

      <h2>4. Intellectual and Industrial Property Rights</h2>
      <p>
        This entire website (design, source code, description and
        characteristics, text, images, trademarks, logos, buttons, software
        files, color combinations, as well as the structure, selection,
        arrangement and presentation of its contents) is protected by Spanish,
        European and international laws on Intellectual and Industrial Property.
        Under no circumstances does access by third parties to this site imply
        any kind of waiver, transmission or total or partial transfer of the
        rights granted by Spanish, European and international legislation in
        this regard.
      </p>
      <p>
        The reproduction, copying, use, distribution, reuse, exploitation,
        making of second copies, sending by mail, transmission, modification,
        transfer or any other act that is done with all or part of the
        information contained in this website that has not been expressly
        authorized by the owner is prohibited.
      </p>
      <p>
        The user undertakes to use the contents in a diligent, correct and
        lawful manner and, in particular, undertakes to refrain from deleting,
        evading or manipulating the copyright and other data identifying the
        rights of OnCampus or its owners that may be incorporated into the
        contents, as well as the technical protection devices or any information
        mechanisms that may be included in the contents.
        <a
          href='http://www.oncampusabroad.com/'
          target='_blank'
          rel='noreferrer noopener'
          style={{ color: '#000000' }}
        >
          www.oncampusabroad.com
        </a>{' '}
        is a domain registered by <b>OnCampus</b>. This domain may not be used,
        except with express prior authorization, in connection with other
        services that are not OnCampus, in a manner that may cause confusion
        among users of the site or in discredit of <b>OnCampus</b>.
      </p>
      <p>
        OnCampus declares its respect for the intellectual and industrial
        property rights of third parties, so if this site could be violating
        their rights, please contact the entity.
      </p>
      <h2>5. Privacy and Data Protection Policy</h2>
      <p>
        The personal data provided within the framework of this website, via the
        form or via email, are confidential and will form part of the register
        of processing activities for which <b>OnCampus </b> is responsible, and
        will be processed by <b>OnCampus </b> in accordance with current
        legislation on the protection of personal data.
      </p>

      <h2>6. Liability and Obligations</h2>

      <h3>6.1 Responsibility for the content</h3>
      <p>
        The content of this website is of a general nature, with an exclusively
        informative purpose and effects of the services and activity carried out
        by <b>OnCampus</b>.
      </p>
      <p>
        However, <b>OnCampus </b> is exempt from any liability for any decision
        taken by the user of the website, as well as for any possible
        inappropriate use of the same. Likewise, OnCampus disclaims liability
        for any information not created by the entity or not published in a
        manner authorized by the same under its name, as well as any liability
        arising from the misuse or reuse of the contents.
      </p>

      <h3>6.2 Responsibility for links to other websites (links)</h3>
      <p>
        The links that may appear on{' '}
        <a
          href='http://www.oncampusabroad.com/'
          target='_blank'
          style={{ color: '#000000' }}
          rel='noreferrer noopener'
        >
          www.oncampusabroad.com
        </a>{' '}
        are for information purposes only and, therefore, <b>OnCampus </b> does
        not control or verify any information, content, products or services
        provided through these websites. Consequently, OnCampus declines any
        responsibility for any aspect related to the linked sites.
      </p>
      <p>
        These links to other sites and the products or services offered by them
        do not imply any endorsement, sponsorship or recommendation by{' '}
        <b>OnCampus</b>.
      </p>
      <p>
        A user&#39;s connection from the OnCampus web site to other sites is
        therefore at the user&#39;s own risk.
      </p>
      <p>
        If the user observes that any link on the <b>OnCampus </b> web page
        leads to web pages whose content could be contrary to the rules or
        fundamental rights, either because of its racist, xenophobic,
        discriminatory, or in support of terrorism or violence, he/she should
        inform <b>OnCampus</b>.
      </p>

      <h3>
        6.3 Liability in the event that the OnCampus website is the destination
        of the link inserted in another website .
      </h3>
      <p>
        With regard to links established by other pages to the <b>OnCampus </b>{' '}
        Web site, the party responsible for the original link must take the
        following into account:
      </p>
      <ul id='l4'>
        <li data-list-text='-'>
          The link must be absolute and complete, that is, it must take the user
          to <b>OnCampus&#39; </b>
          own address. Under no circumstances, unless expressly authorized in
          writing by <b>OnCampus</b>, may the page that makes the link reproduce
          the <b>OnCampus </b> web site in any way by including it as part of
          its web site.
        </li>
        <li data-list-text='-'>
          No erroneous or incorrect information about the <b>OnCampus </b> site
          shall be given.
        </li>
        <li data-list-text='-'>
          If you wish to display any distinctive signs of <b>OnCampus</b>, such
          as trademarks, logos and names, you must have written authorization.
        </li>
        <li data-list-text='-'>
          The owner of the page offering the link must act in good faith and
          must not intend to negatively affect the reputation or good name of{' '}
          <b>OnCampus</b>.
        </li>
        <li data-list-text='-'>
          It is prohibited, unless expressly authorized by
          <b>OnCampus</b>, to register the text elements of the trademark or
          logo, the domain name or company name of <b>OnCampus</b>, as a keyword
          (&quot;metatags&quot; or &quot;metanames&quot;) for the search of Web
          sites carried out through search engines.
        </li>
        <li data-list-text='-'>
          You must request authorization from <b>OnCampus</b>, expressly and in
          writing, if you intend to carry out any other purpose not provided for
          in this Legal Notice.
          <br />
          <br />
          OnCampus assumes no responsibility whatsoever for any aspect of the
          linked website.
        </li>
      </ul>

      <h3>6.4 Responsibility for technical aspects</h3>
      <p>
        OnCampus{' '}
        <span>
          will make every effort to provide a stable and durable service, but in
          no case guarantees the continuity of operation or the operability and
          availability of the website for reasons beyond its responsibility.
        </span>
      </p>
      <p>
        OnCampus{' '}
        <span>
          is not responsible for direct or indirect damages, including damage to
          computer systems and the introduction of viruses on the network,
          arising from Internet browsing necessary for the use of this website.
          For this reason, under no circumstances will{' '}
        </span>
        OnCampus{' '}
        <span>
          be compensated for possible problems resulting from system
          malfunctions.
        </span>
      </p>

      <h3>6.5 User Obligations</h3>
      <p>
        The user will be liable for any damages that <b>OnCampus </b> may suffer
        as a result of a breach by the user of any of the obligations set forth
        in this Legal Notice.
      </p>
      <p>
        The user will be responsible, in any case, for the veracity of the data
        provided, and may not use false identities or impersonate the identity
        of others in the use of this website.
        <b>OnCampus </b> reserves the right to exclude from the registered
        services any user who has provided false information, without prejudice
        to any other legal action that may be taken.
      </p>
      <p>
        The user may not use this service to transmit any kind of illegal,
        threatening, defamatory or any other type of material considered
        offensive or that incites violence or discrimination based on race, sex,
        sexual orientation, ideology, religion, beliefs, physical appearance or
        socioeconomic status, or that in any way violates morality, public order
        or the fundamental rights and freedoms of third parties. Likewise, you
        may not misuse the contents of <b>OnCampus</b>, use them for purposes
        other than those intended, reproduce them, modify them or transform
        them. Any information obtained from the <b>OnCampus </b> site and
        displayed on an external page must duly indicate the source, i.e. the
        name of the site and the link or page from which the information was
        obtained.
      </p>
      <p>
        The user must refrain from engaging in advertising, promotional or
        commercial activities through the <b>OnCampus </b> website.
      </p>
      <p>
        With respect to browsing, the user agrees to diligently and faithfully
        observe the recommendations that <b>OnCampus </b> may establish from
        time to time regarding the use of the site.
      </p>
      <p>
        Likewise, the user agrees to respect the security measures that prevent
        damage or improper use of the information contained on the OnCampus
        website. In this sense, when the use of a user code and password is
        required for any of the services offered, the user must keep it in
        proper custody, undertaking not to cede its use or allow access to it by
        third parties, assuming responsibility for any damages that may arise
        from its improper use. The user will only be exonerated from said
        responsibility if he/she has previously notified the University
        immediately of a security incident in relation to his/her username and
        password.
      </p>
      <p>
        In the case of underage users who wish to access the website, they must
        have the prior permission of their parents, guardians or legal
        representatives, who are warned that, in accordance with current
        legislation, they will be held responsible for all acts carried out by
        minors in their care.
      </p>

      <h2>7. Security Measures</h2>
      <p>
        OnCampus{' '}
        <span>
          complies with the security measures set out in the current legislation
          on data protection in relation to the processing of personal data that
          it carries out, avoiding its alteration, loss and unauthorized or
          unlawful processing or access.
        </span>
      </p>

      <h2>8. Cookie Policy</h2>
      <p>
        OnCampus{' '}
        <span>
          informs about its use of cookies in its Cookie Policy hosted on the
          website.
        </span>
      </p>

      <h2>9. Duration and Modification</h2>
      <p>
        OnCampus{' '}
        <span>
          shall have the right to unilaterally modify the terms and conditions
          stipulated herein, in whole or in part, in order to adapt them to new
          legislation, jurisprudential criteria, industry practices or the
          interests of the entity. Any changes will appear, and will be made
          known to users, in the same way as they appear in this legal notice.
        </span>
      </p>
      <p>
        OnCampus{' '}
        <span>
          may unilaterally terminate, suspend or interrupt the operation of this
          website, without the possibility of requesting any compensation from
          the user. Upon such termination, the user must destroy any information
          about OnCampus that it possesses in any format and that it has
          obtained through the site or through communications made individually
          to the user by OnCampus.
        </span>
      </p>
      <p>
        <span>
          OnCampus shall have no obligation to maintain any content or to
          forward any unread or unsent messages to you or any third party.
        </span>
      </p>

      <h2>10. Legislation and Jurisdiction</h2>

      <p>
        These conditions of use are governed by Spanish law. The conditions that
        appear on this web site are in all cases subject to the laws and courts
        of Spain. In the event of any dispute between the user and OnCampus, the
        parties expressly agree to submit to the courts and tribunals of
        Santiago de Compostela, A Coruña, with express waiver of any other
        jurisdiction.
      </p>
    </InfoPage>
  </MainTemplate>
);

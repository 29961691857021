import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { OrderDetails } from "../models/order-details";

export const useGetOrderDetails = (sk: string | undefined) => {
  const [orderDetails, setOrderDetails] = useState<OrderDetails | undefined>(
    undefined
  );
  useEffect(() => {
    if (!sk) return;
    API.post("oncampus-api", "", {
      response: true,
      queryStringParameters: {
        action: "get-details",
      },
      body: {
        sk,
      },
    })
      .then((response: any) => {
        if (response.data.httpCode === 200 && response.data.orderDetails)
          setOrderDetails(response.data.orderDetails);
      })
      .catch((error: any) => console.log(error));
  }, [sk]);
  return orderDetails;
};

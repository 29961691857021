import styled from "styled-components";
import { device } from "../../../utils/use-device-type";

export const Wrapper = styled.div<{ $scrolling?: boolean }>`
  background-color: #f4ede8;
  position: fixed;
  height: 45px;
  top: 0;
  left: 0;
  padding: 0 8px;
  width: 100%;
  z-index: 150;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 2px 1px 5px 0px rgb(137 135 135 / 18%);
  @media ${device.laptop} {
    height: ${(props) => (props.$scrolling ? "inherit" : "100px")};
    padding: 0 ${(props) => (props.$scrolling ? "8px" : "50px")};
  }
`;

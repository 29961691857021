import styled from "styled-components";
import { MEDIA_URL } from "../../template/main-template/main-templateStyles";

export const ContactWrapper = styled.div`
  background: ${(props) => props.theme.landing.colors.secondary};
  > form,
  > form h2 {
    color: #ffffff;
  }
  padding-top: 60px;
  @media (min-width: 600px) {
    > form {
      max-width: 500px;
      margin: 0 auto;
    }
  }
  @media (min-width: 991px) {
    padding: 30px;
  }
`;

export const Wrapper = styled.div`
  background: ${(props) => props.theme.landing.colors.primary};
  @media (min-width: 991px) {
    background: url("${MEDIA_URL}/side_location.png")
      ${(props) => props.theme.landing.colors.primary} no-repeat right center;
    background-size: 200px;
  }
`;
export const CollageWrapper = styled.div`
  position: relative;
  text-align: center;
  margin: 0 auto;
  padding-top: 40px;
`;

export const ContactCollage = styled.div`
  height: 200px;
  margin-top: 35px;
  position: relative;
  &::after {
    content: " ";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -12px;
    background: url("${MEDIA_URL}/girl_suitcase.webp") no-repeat center;
    background-size: contain;
    height: 200px;
    width: 150px;
    margin: 0 auto;
    display: block;
  }
`;

export const ContactButton = styled.button`
  margin: 20px auto 0 auto;
  width: 80%;
  max-width: 300px;
  display: block;

  @media (min-width: 991px) {
    max-width: 350px;
  }
`;

export const Label = styled.h2`
  text-align: center;
  color: #ffffff;
`;

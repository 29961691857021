import { Link } from "react-router-dom";
import styled from "styled-components";
import { MEDIA_URL } from "../../template/main-template/main-templateStyles";
import { device } from "../../../utils/use-device-type";

export const LogoButton = (props: { path: string }) => (
  <Link reloadDocument to={props.path} style={{ color: "transparent" }}>
    <LogoImg />
  </Link>
);

export const LogoImg = styled.div`
  display: inline-block;
  width: 150px;
  height: 30px;
  background: url("${MEDIA_URL}/mobile/ONCampus_logo.webp") no-repeat;
  background-size: contain;
  @media ${device.laptop} {
    background-image: url("${MEDIA_URL}/ONCampus_logo.webp");
    width: 300px;
    height: 80px;
    cursor: pointer;
  }
`;

import React, { useState, ReactNode } from 'react';
import styled from 'styled-components';

interface TooltipProps {
  tooltipText: string;
  children?: ReactNode;
  isDiscount?: boolean;
}

const Tooltip: React.FC<TooltipProps> = ({
  tooltipText,
  children,
  isDiscount
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <TooltipBox isDiscount={isDiscount} showTooltip={showTooltip}>
      <Box>
        <ChildrenBox isDiscount={isDiscount}>{children}</ChildrenBox>
        <TooltipWrapper
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
          onClick={() => setShowTooltip(!showTooltip)}
          style={{ position: 'relative', display: 'inline-block' }}
        >
          <TooltipInactive style={{ color: '#fff' }}>i</TooltipInactive>
        </TooltipWrapper>
      </Box>
      {showTooltip && (
        <TooltipActive onClick={() => setShowTooltip(!showTooltip)}>
          {tooltipText}
        </TooltipActive>
      )}
    </TooltipBox>
  );
};
interface TooltipBoxProps {
  showTooltip: boolean;
  isDiscount?: boolean;
}
interface ChildrenBoxProps {
  isDiscount?: boolean;
}

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const TooltipBox = styled.div<TooltipBoxProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${props =>
    props.isDiscount ? 'flex-end' : 'space-between'};
  width: 100%;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 10px;
  }
`;
const ChildrenBox = styled.div<ChildrenBoxProps>`
  width: ${props => (props.isDiscount ? 'auto' : '100%')};
  margin-right: 10px;
`;
const TooltipActive = styled.div`
  background-color: #becdfd;
  border: 1px solid #83a0fc;
  border-radius: 5px;
  padding: 12px;
  z-index: 1;
  width: 272px;
  height: auto;
  text-align: left;
  font: normal normal normal 10px/14px;
  margin-top: 15px;

  @media (min-width: 768px) {
    position: absolute;
    top: 25%;
    left: 100%;
    transform: translateY(-50%);
    margin-left: 25px;
  }
`;
const TooltipWrapper = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
`;
const TooltipInactive = styled.div`
  width: 26px;
  height: 26px;
  background: #83a0fc 0% 0% no-repeat padding-box;
  border: 1px solid #475991;
  opacity: 1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default Tooltip;

import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import StepWrapper from '../stepWrapper';
import { NewFlowBox } from './PersonalDetails';
import { TextInput } from '../../atoms/text-input/text-input';
import { validateString } from '../../../utils/form-validator';
import { postToken } from '../../../redux/api/SecondFlowApi';
import Success from '../../Lottie/success';
import Error from '../../Lottie/error';
import { StepFormWrapper } from '../../molecules/form-tab-presenter/form-tabStyles';
import { ContactBox } from '../../molecules/contact-box/contact-box';
import { Link } from 'react-router-dom';
import { SessionManager } from '../../../utils/session-manager';
import { Button } from '../../template/main-template/main-templateStyles';
import { useTranslation } from 'react-i18next';

const ExtensionFlow = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [documentNumber, setDocumentNumber] = useState<string>('');
  const [documentNumberError, setDocumentNumberError] = useState(false);
  const [apiSuccess, setApiSuccess] = useState(false);
  const [apiError, setApiError] = useState(false);
  const tokenApi = useAppSelector((state) => state.secondFlowApi.tokenApi);
  useEffect(() => {
    if (tokenApi.loading === 'succeeded' && tokenApi.response.data.httpCode === 200) {
      setApiSuccess(true);
    } else if (tokenApi.loading === 'succeeded' && tokenApi.response.data.httpCode !== 200) {
      setApiError(true);
    }
  }, [tokenApi.loading, tokenApi.response]);
  const validate = () => {
    const documentNumberIsValid = validateString(documentNumber);
    setDocumentNumberError(!documentNumberIsValid);
    return documentNumberIsValid;
  };
  const handleContinue = () => {
    const isValid = validate();
    if (isValid) {
      dispatch(postToken(documentNumber));
    }
  };

  let content;
  switch (true) {
    case apiSuccess:
      content = (
        <StepFormWrapper $summary>
          <Success text={t('success-message-verification')} />
          <ContactBox icon={false} />
        </StepFormWrapper>
      );
      break;
    case apiError === true:
      content = (
        <StepFormWrapper $summary>
          <Error text={t('error-message-verification')} />
          <Link
            reloadDocument
            to='/onboarding?extendedInsurance=true'
            style={{ textAlign: 'center' }}
          >
            <Button
              id='ExtensionFlowError_button-back-to-extension-flow-start_Button'
              onClick={() => {
                SessionManager.setButtonId(
                  'ExtensionFlowError_button-back-to-extension-flow-start_Button'
                );
              }}
            >
              {t('BACK')}
            </Button>
          </Link>
        </StepFormWrapper>
      );

      break;
    default:
      content = (
        <StepWrapper next={{ f: handleContinue, label: 'NEXT' }}>
          <NewFlowBox>
            <TextInput
              field={{
                id: 'documentNumber-packs',
                type: 'string',
                mandatory: true,
              }}
              onChange={setDocumentNumber}
              value={documentNumber}
              warning={documentNumberError}
            />
          </NewFlowBox>
        </StepWrapper>
      );
  }

  return content;
};

export default ExtensionFlow;

import Lottie from "lottie-react";
import success from "./LottieJson/success.json";
import styled from "styled-components";

const Success = (props: { text: string }) => {
  return (
    <SuccessWrapper>
      <Lottie animationData={success} loop={false} style={{ height: 100 }} />
      <p>{props.text}</p>
    </SuccessWrapper>
  );
};

export default Success;

const SuccessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

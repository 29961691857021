import { Wrapper } from './topbarStyles';
import { LogoButton } from '../../atoms/logo-button/logo-button';
import { NavigationMenu } from '../../molecules/navigation-menu/navigation-menu';
import { LAPTOP_HEADER_NAVIGATION } from '../header/header-navigation';
import { useLocation } from 'react-router-dom';
interface TopBarProps {
  blogLoaded?: boolean;
}
export const TopBar: React.FC<TopBarProps> = ({ blogLoaded }) => (
  <Wrapper>
    <LogoButton path='/' />
    {useLocation().pathname !== '/onboarding' && (
      <NavigationMenu
        pathDefs={LAPTOP_HEADER_NAVIGATION}
        blogLoaded={blogLoaded}
      />
    )}
  </Wrapper>
);

import { MEDIA_URL_ROOT } from '../../template/main-template/main-templateStyles';
import { RequiredLabel } from '../../atoms/check-box/check-box';
import { useTranslation } from 'react-i18next';

export const ConditionsAndProduct = () => {
  const { t } = useTranslation();
  return (
    <span>
      I have read and agree to the
      <a
        href={`${MEDIA_URL_ROOT}/documents/Insurance_Conditions_OnCampusAbroad.pdf`}
        target='_blank'
        rel='noopener noreferrer'
        style={{ display: 'inlineBlock', margin: '0 3px' }}
      >
        general conditions of the policy
      </a>
      and
      <a
        href={`${MEDIA_URL_ROOT}/documents/IPID_OnCampus_Abroad.pdf`}
        target='_blank'
        rel='noopener noreferrer'
        style={{ display: 'inlineBlock', margin: '0 3px' }}
      >
        the document of the product
      </a>
      <RequiredLabel>{t('form-required')}</RequiredLabel>
    </span>
  );
};

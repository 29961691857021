import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import StepWrapper from '../stepWrapper';
import {
  OrderFlowTabs,
  setTravelDetails,
  setTab
} from '../../../redux/orderFlow/orderFlowSlice';
import { NewFlowBox } from './PersonalDetails';
import {
  getCountries,
  OriginCountries,
  DestinationCountries
} from '../../../models/countries';
import { SelectInput } from '../../atoms/select-input/select-input';
import { DatePicker } from '../../atoms/date-picker/date-picker';
import {
  validateNewCountry,
  validateReturnDate,
  validateDepartureDate
} from '../../../utils/form-validator';
import {
  getTomorrowDate,
  getMaxReturnDate,
  getMaxDepartureDate,
  getMinReturnDate
} from '../../atoms/date-picker/date-picker-functions';
import Tooltip from '../../atoms/tooltip/tooltip';
import { useTranslation } from 'react-i18next';
import { getLanguage } from '../../../utils/i18n-utils';

const TravelDetails = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const language = getLanguage();
  const [homeCountry, setHomeCountry] = useState<string>(
    useAppSelector(state => state.orderFlow.homeCountry) || ''
  );
  const [destinationCountry, setDestinationCountry] = useState<string>(
    useAppSelector(state => state.orderFlow.destinationCountry) || ''
  );
  const [departureDate, setDepartureDate] = useState<string>(
    useAppSelector(state => state.orderFlow.departureDate) || getTomorrowDate()
  );
  const [returnDate, setReturnDate] = useState<string>(
    useAppSelector(state => state.orderFlow.returnDate) || getMinReturnDate()
  );
  const [homeCountryError, setHomeCountryError] = useState(false);
  const [destinationCountryError, setDestinationCountryError] = useState(false);
  const [departureDateError, setDepartureDateError] = useState('');
  const [returnDateError, setReturnDateError] = useState('');
  const [sameCountryError, setSameCountryError] = useState(false);
  const validate = () => {
    const homeCountryIsValid = validateNewCountry(homeCountry, OriginCountries);
    const destinationCountryIsValid = validateNewCountry(
      destinationCountry,
      DestinationCountries
    );

    const departureDateIsValid = validateDepartureDate(
      returnDate,
      departureDate
    );
    const returnDateIsValid = validateReturnDate(returnDate, departureDate);
    const sameCountry = homeCountry === destinationCountry;
    setSameCountryError(sameCountry);
    setHomeCountryError(!homeCountryIsValid);
    setDestinationCountryError(!destinationCountryIsValid);
    setDepartureDateError(departureDateIsValid);
    setReturnDateError(returnDateIsValid);
    return (
      homeCountryIsValid &&
      destinationCountryIsValid &&
      departureDateIsValid.length === 0 &&
      returnDateIsValid.length === 0 &&
      !sameCountry
    );
  };

  const handleContinue = () => {
    const isValid = validate();
    if (isValid) {
      dispatch(
        setTravelDetails({
          homeCountry,
          destinationCountry,
          departureDate,
          returnDate
        })
      );
      dispatch(setTab(OrderFlowTabs.IdentificationDetails));
    }
  };
  const handleBack = () => {
    dispatch(setTab(OrderFlowTabs.PersonalDetails));
  };
  return (
    <StepWrapper
      next={{ f: handleContinue, label: 'NEXT' }}
      back={{ f: handleBack, label: 'BACK' }}
    >
      <NewFlowBox>
        <Tooltip
          children={
            <SelectInput
              field={{
                id: 'homeCountry',
                type: 'country',
                mandatory: true
              }}
              options={getCountries(language, OriginCountries)}
              onChange={setHomeCountry}
              value={homeCountry}
              warning={homeCountryError}
            />
          }
          tooltipText={t('tooltip-country')}
        />
        <SelectInput
          field={{
            id: 'destinationCountry',
            type: 'country',
            mandatory: true
          }}
          options={getCountries(language, DestinationCountries)}
          onChange={setDestinationCountry}
          value={destinationCountry}
          warning={destinationCountryError || sameCountryError}
        />
        <DatePicker
          minDate={{ enabled: true, date: getTomorrowDate() }}
          maxDate={{ enabled: true, date: getMaxDepartureDate() }}
          field={{
            id: 'departureDate',
            type: 'future-date',
            mandatory: true
          }}
          onChange={setDepartureDate}
          value={departureDate}
          warning={departureDateError}
        />
        <DatePicker
          minDate={{
            enabled: true,
            date: getMinReturnDate(returnDate, departureDate)
          }}
          maxDate={{ enabled: true, date: getMaxReturnDate(departureDate) }}
          field={{
            id: 'returnDate',
            type: 'future-date',
            mandatory: true
          }}
          onChange={setReturnDate}
          value={returnDate}
          warning={returnDateError}
        />
      </NewFlowBox>
    </StepWrapper>
  );
};

export default TravelDetails;

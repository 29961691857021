import {
  addMonths,
  differenceInMonths,
  isValid,
  addYears,
  addDays,
  subDays,
  subMonths,
} from "date-fns";
import { getMonthsNumber } from "../../../utils/prices-calculator";

export const MAX_RETURN_DATE = "2025-06-30";
export const MINIMUM_PERIOD_MONTHS = 1;
export const MAXIMUM_PERIOD_MONTHS = 12;

export const serializeDate = (date: Date) => {
  return date.toISOString().split("T")[0];
};

export const deserializeDate = (dateString: string) => {
  return new Date(dateString);
};

export const getTomorrowDate = () => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow.toISOString().split("T")[0];
};

export const getEighteenYearsAgoDate = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 18);
  return date.toISOString().split("T")[0];
};
export const getSixtyNineYears364DaysAgoDate = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 69);
  date.setDate(date.getDate() - 364);
  return date.toISOString().split("T")[0];
};

export const getMonthsDifference = (date1: string, date2: string) => {
  const date1Ser = deserializeDate(date1);
  const date2Ser = deserializeDate(date2);
  if (!isValid(date1Ser) || !isValid(date2Ser)) return -1;
  return differenceInMonths(date1Ser, date2Ser);
};

export const getMaxReturnDate = (departureDate: string) => {
  if (!departureDate.length) return MAX_RETURN_DATE;
  return serializeDate(addYears(deserializeDate(departureDate), 1));
};

export const getMinReturnDate = (
  returnDate?: string,
  departureDate?: string
) => {
  let dd = departureDate ? departureDate : getTomorrowDate();
  const rd = returnDate ? returnDate : dd;
  let newDate = deserializeDate(dd);
  if (!isValid(newDate)) return dd;
  if (getMonthsNumber(rd, dd) > 1 || dd !== getMaxDepartureDate()) {
    return subDays(addMonths(newDate, MINIMUM_PERIOD_MONTHS), 1)
      .toISOString()
      .split("T")[0];
  }
  return addMonths(newDate, MINIMUM_PERIOD_MONTHS).toISOString().split("T")[0];
};

export const getMinDepartureDate = (
  returnDate: string,
  departureDate: string
) =>
  getMonthsNumber(returnDate, departureDate) < 0
    ? getTomorrowDate()
    : departureDate;

export const getMinReturnDateN = (departureDate: any) =>
  subDays(addMonths(deserializeDate(departureDate), MINIMUM_PERIOD_MONTHS), 1)
    .toISOString()
    .split("T")[0];

export const getMaxMonthsDate = (departureDate: any) =>
  subDays(addMonths(deserializeDate(departureDate), MAXIMUM_PERIOD_MONTHS), 1)
    .toISOString()
    .split("T")[0];

export const getMaxDepartureDate = () =>
  serializeDate(subMonths(addDays(deserializeDate(MAX_RETURN_DATE), 1), 1));

import styled from "styled-components";
import { MEDIA_URL } from "../../template/main-template/main-templateStyles";

export const HamburgerMenuButton = styled.button<{ expanded: any }>`
  height: 35px;
  width: 35px;
  background: url("${MEDIA_URL}/menu${(props) =>
      props.expanded ? "_close" : ""}.png")
    center no-repeat;
  background-size: contain;
  border-radius: 0;
  min-width: 0;
  color: transparent;
`;

export const MenuMobile = styled.div`
  height: 100vh;
  width: 100%;
  position: fixed;
  overflow: hidden;
  top: 45px;
  left: 0;
  background: #f3ece8;
  > div a button {
    display: none;
  }
  padding: 60px 20px;
  @media (min-width: 991px) {
    > div a button {
      display: inline-block;
      padding: 0;
    }
  }
`;

export const MenuItems = styled.div`
  > a:link,
  > a:visited,
  > a:hover,
  > a:active,
  a.menuLink {
    color: ${(props) => props.theme.landing.colors.secondary};
    font-weight: bold;
  }
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 3px;
  @media (min-width: 991px) {
    align-items: center;
    padding: 0;
    background: none;
    max-width: 550px;
    flex-direction: row;
    gap: 0;
  }
`;

export const MenuItem = styled.a`
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  padding: 5px 0 5px 20px;
  background: url("${MEDIA_URL}/arrow.png") no-repeat center left;
  background-size: 12px;

  @media (min-width: 600px) {
    background: none;
    cursor: pointer;
    padding: 0 10px;
  }
`;

import { API } from "aws-amplify";
import { initialApiState, ApiCallBase } from "./apiConfiguration";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface TokenApiCall extends ApiCallBase {
  response: any;
}
interface UserApiCall extends ApiCallBase {
  response: any;
}

interface State {
  tokenApi: TokenApiCall;
  userApi: UserApiCall;
}

const initialState: State = {
  tokenApi: initialApiState,
  userApi: initialApiState,
};

export const postToken = createAsyncThunk(
  "secondFlow/postToken",
  async (args: String) => {
    try {
      const TokenResponse = await API.post("oncampus-token-api", "", {
        response: true,
        queryStringParameters: {
          action: "post-token-request",
        },
        body: args,
      });
      return TokenResponse;
    } catch (error) {
      return console.log(error);
    }
  }
);

export const getUserDetails = createAsyncThunk(
  "secondFlow/getUserDetails",
  async (token: String) => {
    try {
      const UserDetailsResponse = await API.post("oncampus-token-api", "", {
        queryStringParameters: {
          action: "get-user-details",
        },
        body: { token },
      });
      return UserDetailsResponse;
    } catch (error) {
      return console.log(error);
    }
  }
);

const SecondFlowSlice = createSlice({
  name: "secondFlow",
  initialState,
  reducers: {
    resetSecondFlow: (state) => {
      state.tokenApi = {
        ...initialApiState,
        response: state.tokenApi.response,
      };
      state.userApi = {
        ...initialApiState,
        response: state.userApi.response,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postToken.pending, (state) => {
      state.tokenApi.loading = "pending";
    });
    builder.addCase(postToken.fulfilled, (state, action) => {
      state.tokenApi.loading = "succeeded";
      state.tokenApi.response = action.payload;
    });
    builder.addCase(postToken.rejected, (state, action) => {
      state.tokenApi.loading = "failed";
      state.tokenApi.error = action.error.message;
    });
    builder.addCase(getUserDetails.pending, (state) => {
      state.userApi.loading = "pending";
    });
    builder.addCase(getUserDetails.fulfilled, (state, action) => {
      state.userApi.loading = "succeeded";
      state.userApi.response = action.payload;
    });
    builder.addCase(getUserDetails.rejected, (state, action) => {
      state.userApi.loading = "failed";
      state.userApi.error = action.error.message;
    });
  },
});

export const { resetSecondFlow } = SecondFlowSlice.actions;
export default SecondFlowSlice.reducer;

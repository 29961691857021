import React, { useState } from 'react';
import StepWrapper from '../stepWrapper';
import { useAppDispatch } from '../../../redux/hooks';
import { RadioButtonGroup, Box } from 'grommet';
import styled from 'styled-components';
import {
  setTab,
  OrderFlowTabs,
  setHealthCard,
  setLegalConsentOne,
  setNewsConsent,
} from '../../../redux/orderFlow/orderFlowSlice';
import { useTranslation } from 'react-i18next';

const HealthCard = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const options = [
    { label: t('checkbox-healthInsuranceTrue'), value: true },
    { label: t('checkbox-healthInsuranceFalse'), value: false },
  ];
  const [value, setValue] = useState<boolean>(true);

  const handleContinue = () => {
    dispatch(setHealthCard({ healthInsurance: value }));
    dispatch(setTab(OrderFlowTabs.ExtendedInsurance));
  };
  const handleBack = () => {
    dispatch(setLegalConsentOne({ legalConsentOne: false }));
    dispatch(setNewsConsent({ newsConsent: false }));
    dispatch(setTab(OrderFlowTabs.PersonalDetailsConfirmation));
  };
  return (
    <StepWrapper
      next={{ f: handleContinue, label: 'NEXT' }}
      back={{ f: handleBack, label: 'BACK' }}
    >
      <Box align='center'>
        <Box align='left'>
          <p style={{ color: 'white' }}>{t('form-sub-tab-4')}</p>
          <RadioButtonGroupStyled
            name='doc'
            options={options}
            value={value}
            onChange={(event: any) => setValue(event.target.value === 'true' ? true : false)}
            align='left'
          />
          <p style={{ color: 'white' }}>{t(`form-sub-${value ? 'true' : 'false'}-tab-4`)}</p>
        </Box>
      </Box>
    </StepWrapper>
  );
};

export default HealthCard;

const RadioButtonGroupStyled = styled(RadioButtonGroup)`
  color: white;
  align-items: left;
  svg {
    fill: #06b192;
  }
  div {
    div {
      box-sizing: border-box;
      border-radius: 100%;
      border: 1px solid white;
    }
  }
`;

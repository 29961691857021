export const attributes = [
  {
    content: `service-1`,
  },
  {
    content: `service-2`,
  },
  {
    content: `service-3`,
  },
];

export const characteristics = [
  {
    EN: [
      {
        icon: 'shield',
        text: 'characteristic-1',
      },
      {
        icon: 'pills',
        text: 'characteristic-2',
      },
      {
        icon: 'plane',
        text: 'characteristic-3',
      },
      {
        icon: 'clock',
        text: 'characteristic-4',
      },
      {
        icon: 'suitcase',
        text: 'characteristic-5',
      },
      {
        icon: 'chat',
        text: 'characteristic-6',
      },
      {
        icon: 'shield_broken',
        text: 'characteristic-7',
      },
      {
        icon: 'alarm',
        text: 'characteristic-8',
      },
    ],
  },
];

import styled from "styled-components";

interface InfoBoxType {
  sequence?: number;
}
export const InfoBox = styled.div<InfoBoxType>`
  border-radius: 50%;
  padding: 10px;
  display: inline-block;
  width: 125px;
  height: 125px;
  position: relative;
  color: #ffffff;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > p {
    text-align: center;
    font-size: 0.8rem;
    margin: 0 5px;
  }
  & p:nth-child(2) {
    color: ${(props) => props.theme.landing.colors.primary};
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 110%;
    font-family: "Merriweather", serif;
  }
  &:nth-child(1) {
    background-color: ${(props) => props.theme.landing.colors.primary};
    p:nth-child(2) {
      color: ${(props) => props.theme.landing.colors.third};
      font-family: "Merriweather", serif;
    }
  }
  &:nth-child(2) {
    background-color: ${(props) => props.theme.landing.colors.third};
  }

  &:nth-child(3) {
    background-color: ${(props) => props.theme.landing.colors.lightBlue};
  }
  @media (min-width: 400px) {
    font-size: 1rem;
  }
  @media (min-width: 991px) {
    width: 180px;
    height: 180px;
    padding: 30px;

    > p {
      font-size: 1.2rem;
    }
    & p:nth-child(2) {
      font-size: 1.7rem;
      line-height: 120%;
    }
    &:nth-child(1),
    &:nth-child(3) {
      margin-top: 50px;
    }
  }
`;

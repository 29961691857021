import React, { useState } from 'react';
import { FormField } from '../../../models/form';
import { InputWrapper } from '../input-wrapper/input-wrapper';
import styled from 'styled-components';
import { device } from '../../../utils/use-device-type';
import { useTranslation } from 'react-i18next';

interface CheckBoxProps {
  field: FormField;
  value: boolean;
  onChange: (val: boolean) => void;
  warning: boolean;
}

const CheckBox: React.FC<CheckBoxProps> = ({ field, value, onChange, warning }) => {
  const [isChecked, setIsChecked] = useState<boolean>(value);
  const { t } = useTranslation();
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setIsChecked(checked);
    onChange(checked);
  };

  return (
    <InputWrapper
      component={
        <CheckBoxBox>
          <input type='checkbox' checked={isChecked} onChange={handleOnChange} />
          <p>{t(`checkbox-${field.id}`)}</p>
        </CheckBoxBox>
      }
      warning={warning}
      id={field.id}
    />
  );
};

const CheckBoxBox = styled.div`
  all: initial;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const RequiredLabel = styled.span`
  color: ${(props) => props.theme.landing.colors.negative};
  font-size: 0.8rem;
  font-style: italic;
`;

export const RequiredCheckWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 5px;
  > span {
    > a:link,
    > a:visited,
    > a:hover,
    > a:active {
      color: ${(props) => props.theme.landing.colors.third};
    }
  }
  @media ${device.tablet} {
    gap: 6px;
    margin: 4px 0;
    align-items: center;
  }
  @media ${device.laptop} {
    gap: 12px;
  }
`;

export const Checkboxes = styled.div`
  padding: 0 8px;

  @media ${device.tablet} {
    padding: 0 20px;
  }
`;

export default CheckBox;

import API from '@aws-amplify/api';
import { useEffect, useState } from 'react';

export const useGetPaymentUrl = (orderId: string | undefined) => {
  const [url, setUrl] = useState<string | undefined>(undefined);
  const [data, setData] = useState<string | undefined>(undefined);
  useEffect(() => {
    if (!orderId) return;
    API.post('oncampus-api', '', {
      response: true,
      queryStringParameters: {
        action: 'get-details',
      },
      body: {
        orderId,
      },
    })
      .then((response: any) => {
        if (response.data.httpCode === 200 && response.data.paymentUrl)
          setUrl(response.data.paymentUrl);
        if (response.data.httpCode === 200 && response.data.data) setData(response.data.data);
      })
      .catch((error: any) => console.log(error));
  }, [orderId]);
  return {
    url: url,
    data: data,
  };
};

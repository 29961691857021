import { Box, Text } from 'grommet';
import { StepFormWrapper } from '../form-tab-presenter/form-tabStyles';
import { NextBackButtons } from '../../atoms/nextback/next-back-buttons';
import error from '../../../assets/error-cross.svg';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const PurchaseErrorContainer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleContinue = () => {
    navigate('/');
  };
  const handleBack = () => {
    navigate('/');
  };
  return (
    <Box align='center'>
      <StepFormWrapper $summary>
        <Box align='center'>
          <img src={error} style={{ height: '60px', width: '60px' }} alt='error' />
          <h1 style={{ marginTop: 20 }}>{t('purchase-error-title')}</h1>
          <Text textAlign='center'>{t('purchase-error-subtitle')}</Text>
        </Box>
        <NextBackButtons
          next={{ f: handleContinue, label: 'TRY-AGAIN' }}
          back={{ f: handleBack, label: 'BACK' }}
        />
      </StepFormWrapper>
    </Box>
  );
};

import React, { useState } from 'react';
import { TextInput } from '../../atoms/text-input/text-input';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import StepWrapper from '../stepWrapper';
import {
  OrderFlowTabs,
  setPersonalDetails,
  setTab
} from '../../../redux/orderFlow/orderFlowSlice';
import { validateName } from '../../../utils/form-validator';

const PersonalDetails = () => {
  const dispatch = useAppDispatch();
  const [firstName, setFirstName] = useState<string>(
    useAppSelector(state => state.orderFlow.firstName) || ''
  );
  const [lastName, setLastName] = useState<string>(
    useAppSelector(state => state.orderFlow.lastName) || ''
  );
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);

  const validate = () => {
    const firstNameIsValid = validateName(firstName);
    const lastNameIsValid = validateName(lastName);
    setFirstNameError(!firstNameIsValid);
    setLastNameError(!lastNameIsValid);
    return firstNameIsValid && lastNameIsValid;
  };
  const handleContinue = () => {
    const isValid = validate();
    if (isValid) {
      dispatch(
        setPersonalDetails({
          firstName,
          lastName
        })
      );
      dispatch(setTab(OrderFlowTabs.TravelDetails));
    }
  };

  return (
    <StepWrapper next={{ f: handleContinue, label: 'NEXT' }}>
      <NewFlowBox>
        <TextInput
          field={{ id: 'firstName', type: 'string', mandatory: true }}
          onChange={setFirstName}
          value={firstName}
          warning={firstNameError}
        />
        <TextInput
          field={{ id: 'lastName', type: 'string', mandatory: true }}
          onChange={setLastName}
          value={lastName}
          warning={lastNameError}
        />
      </NewFlowBox>
    </StepWrapper>
  );
};

export const NewFlowBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export default PersonalDetails;

import React from 'react';
import { Box } from 'grommet';
import { StepFormWrapper } from '../molecules/form-tab-presenter/form-tabStyles';
import { NextBackButtons } from '../atoms/nextback/next-back-buttons';
import { OrderFlowTabs } from '../../redux/orderFlow/orderFlowSlice';
interface StepWrapperProps {
  children?: React.ReactNode;
  tab?: number;
  next: { f: () => void; label: string };
  back?: { f: () => void; label: string } | null;
}
const StepWrapper: React.FC<StepWrapperProps> = ({
  children,
  tab,
  next,
  back
}) => {
  return (
    <Box
      width='100%'
      animation={[
        { type: 'fadeIn', size: 'xlarge' },
        { type: 'slideRight', size: 'xlarge' }
      ]}
      align='center'
      gap='medium'
      key={`form-tab-${tab}`}
    >
      <StepFormWrapper>
        {children}{' '}
        <NextBackButtons
          next={next}
          back={tab === OrderFlowTabs.PersonalDetails ? null : back}
        />
      </StepFormWrapper>
    </Box>
  );
};

export default StepWrapper;

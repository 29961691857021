import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SessionManager } from '../../../utils/session-manager';
import { useDeviceType } from '../../../utils/use-device-type';
import { MEDIA_URL_ROOT } from '../../template/main-template/main-templateStyles';
import {
  HamburgerMenuButton,
  MenuItem,
  MenuItems,
  MenuMobile
} from './navigation-menuStyles';
import { useTranslation } from 'react-i18next';

export type NavigationPathDefinition = {
  id: string;
  path: string;
  scrollTo?: boolean;
  newWindow?: boolean;
  name?: string;
};

interface NavigationMenuProps {
  pathDefs: NavigationPathDefinition[];
  t?: Function;
  expandedMenu?: boolean;
  setExpandMenu?: any;
  blogLoaded?: boolean;
}

export const NavigationMenu = (props: NavigationMenuProps) => {
  const { t } = useTranslation();
  const [expandedMenu, setExpandMenu] = useState(false);
  const deviceType = useDeviceType();
  return (
    <>
      {deviceType === 'mobile' ? (
        <MobileMenu
          {...props}
          t={t}
          expandedMenu={expandedMenu}
          setExpandMenu={setExpandMenu}
        />
      ) : (
        <RenderMenuItems {...props} t={t} />
      )}
    </>
  );
};

export const RenderMenuItems = (props: NavigationMenuProps) => {
  const { t } = props;

  const location = useLocation();
  const blogLoaded = props.blogLoaded;
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const id = params.get('id');

    if (id && blogLoaded) {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        params.delete('id');
        const newSearch = params.toString() ? '?' + params.toString() : '';
        window.history.replaceState({}, '', `${location.pathname}${newSearch}`);
      }
    }
  }, [location, t, blogLoaded]);

  const handleClickScroll = (id: string) => {
    if (window.location.pathname !== '/') {
      window.location.href = `/?id=${id}`;
    } else {
      const element = document.getElementById(id);
      if (element) {
        blogLoaded && element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };
  if (!t) {
    console.error('t is null or undefined');
    return null;
  }
  return (
    <MenuItems>
      {props.pathDefs.map(pathDef => {
        const isDoc = pathDef.path.slice(-3) === 'pdf';
        return (
          <MenuItem
            key={`nav-menu-${pathDef.path}`}
            href={
              pathDef.scrollTo
                ? undefined
                : isDoc
                ? `${MEDIA_URL_ROOT}/${pathDef.path}`
                : pathDef.path
            }
            onClick={() =>
              pathDef.scrollTo ? handleClickScroll(pathDef.id) : null
            }
            className='menuLink'
            target={pathDef.newWindow ? '_blank' : undefined}
            rel='noopener noreferrer'
          >
            {t(`${pathDef.name}`)}
          </MenuItem>
        );
      })}
      <Link reloadDocument to='/onboarding' rel='noopener noreferrer'>
        <button
          id='NavigationMenu_BUY-INSURANCE_Button'
          onClick={() => {
            SessionManager.setButtonId('NavigationMenu_BUY-INSURANCE_Button');
          }}
          style={{
            padding: '8px 12px',
            minWidth: 100,
            width: 180,
            marginLeft: 20,
            fontSize: '.9rem'
          }}
        >
          {t('landing.topBar.button-buy')}
        </button>
      </Link>
    </MenuItems>
  );
};
interface MobileMenuProps extends NavigationMenuProps {
  t: any;
  expandedMenu: boolean;
  setExpandMenu: React.Dispatch<React.SetStateAction<boolean>>;
}
const MobileMenu: React.FC<MobileMenuProps> = ({
  t,
  expandedMenu,
  setExpandMenu,
  ...props
}) => {
  return (
    <>
      <HamburgerMenuButton
        expanded={expandedMenu}
        onClick={() => setExpandMenu(!expandedMenu)}
      >
        Menu
      </HamburgerMenuButton>
      {expandedMenu && (
        <MenuMobile onClick={() => setExpandMenu(false)}>
          <RenderMenuItems {...props} t={t} />
        </MenuMobile>
      )}
    </>
  );
};

import React, { useState, useEffect, useRef } from 'react';
import { Header } from '../../organisms/header-landing/header';
import { PageWrapper } from '../../template/main-template/main-templateStyles';
import { Services } from '../../organisms/services/Services';
import { FrequentQuestions } from '../../organisms/frequent-questions/frequentQuestions';
import { Coverages } from '../../organisms/coverages/Coverages';
import { Contact } from '../../organisms/contact/contact';
import { Footer } from '../../organisms/footer/footer';
import { TopBar } from '../../organisms/topbar/topbar';
import { ProductPolicy } from '../../organisms/productPolicy/packs';
import { Downloads } from '../../organisms/productPolicy/downloads';
import { PopularBlog } from '../../organisms/blog/popular-blog';
import { ErgoSection } from '../../organisms/ergo/ergoSection';
import { Chatbot } from '../../chatbot/chatbot';

export const LandingPage = () => {
  const [isBlogLoaded, setIsBlogLoaded] = useState(false);
  const [isContactLoaded, setIsContactLoaded] = useState(false);
  const faqRef = useRef<HTMLDivElement>(null);
  const contactRef = useRef<HTMLDivElement>(null);

  const handleBlogLoaded = () => {
    setIsBlogLoaded(true);
  };

  const handleContactLoaded = () => {
    setIsContactLoaded(true);
  };

  useEffect(() => {
    let reference = undefined;
    const urlParams = new URLSearchParams(window.location.search);
    const sectionId = urlParams.get('id');

    if (sectionId) {
      if (sectionId === 'FAQ' && isBlogLoaded) {
        reference = faqRef;
      } else if (sectionId === 'CONTACT' && isContactLoaded) {
        reference = contactRef;
      }
      reference?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  }, [isBlogLoaded, isContactLoaded]);

  return (
    <PageWrapper>
      <TopBar blogLoaded={isBlogLoaded} />
      <Header />
      <Services />
      <Coverages />
      <ProductPolicy />
      <Downloads />
      <ErgoSection />
      <PopularBlog onLoad={handleBlogLoaded} />
      <FrequentQuestions ref={faqRef} />
      <Contact ref={contactRef} onLoad={handleContactLoaded} />
      {/* <Chatbot /> */}
      <Footer />
    </PageWrapper>
  );
};

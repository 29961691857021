import { Box, Markdown } from 'grommet';
import { OrderDetails } from '../../../models/order-details';
import { processOrderDetailText } from '../../../utils/text-processor';
import { useTranslation } from 'react-i18next';

export const RequestSummary = (props: { orderDetails: OrderDetails }) => {
  return (
    <Box gap='medium' pad='medium'>
      {SUMMARY_PARAGRAPHS.map((p) => (
        <MarkdownParagraph key={`summary-${p}`} label={p} orderDetails={props.orderDetails} />
      ))}
    </Box>
  );
};

const MarkdownParagraph = (props: { label: string; orderDetails: OrderDetails }) => {
  const { t } = useTranslation();
  return <Markdown>{` ${processOrderDetailText(t(props.label), props.orderDetails)}`}</Markdown>;
};

const SUMMARY_PARAGRAPHS = ['one', 'two', 'three', 'four'].map((l) => `request-summary-${l}`);

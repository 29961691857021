import React, { useState } from 'react';
import { Box, CheckBox } from 'grommet';
import { StepFormWrapper } from '../../molecules/form-tab-presenter/form-tabStyles';
import {
  setTab,
  OrderFlowTabs,
  setLegalConsentTwo,
  setDiscount,
  OrderFlow,
  setProducts
} from '../../../redux/orderFlow/orderFlowSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { NextBackButtons } from '../../atoms/nextback/next-back-buttons';
import {
  checkIdRepetition,
  postOrderDetails
} from '../../../redux/api/OrderApi';
import ReCAPTCHA from 'react-google-recaptcha';
import OrderSummaryCalculation from '../../molecules/order-summary/order-summary-calculation';
import { ConditionsAndProduct } from '../../molecules/legal-links/conditions-and-product';
import { RequiredCheckWrapper } from '../../atoms/check-box/check-box';
import { useTranslation } from 'react-i18next';

const OrderSummary = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const orderDetails = useAppSelector(state => state.orderFlow);
  const legalConsentTwo = useAppSelector(
    state => state.orderFlow.legalConsentTwo
  );
  const [recaptcha, setRecaptcha] = useState<boolean>(false);
  const { currentTab, flow, ...orderDetailsWithoutHandlers } = orderDetails;
  const [privacyCheck, setPrivacyCheck] = useState<boolean>(
    orderDetails.legalConsentTwo
  );
  const currentFlow = useAppSelector(state => state.orderFlow.flow);
  const flow2products = orderDetails.flow2Products;
  //@ts-ignore
  const Environment = window.OPSEEKER_CONFIG.ENVIRONMENT;
  const handleContinue = () => {
    if (
      privacyCheck &&
      (recaptcha || Environment === 'staging' || Environment === 'qa')
    ) {
      if (currentFlow === OrderFlow.Flow1) {
        dispatch(checkIdRepetition(orderDetailsWithoutHandlers.documentNumber));
      } else {
        dispatch(postOrderDetails(orderDetailsWithoutHandlers));
      }
    }
  };
  const handleBack = () => {
    if (currentFlow === OrderFlow.Flow2) {
      flow2products && dispatch(setProducts({ products: flow2products }));
    }
    setRecaptcha(false);
    setPrivacyCheck(false);
    dispatch(
      setDiscount({
        discountCode: '',
        discount: false
      })
    );
    dispatch(setLegalConsentTwo({ legalConsentTwo: false }));
    dispatch(setTab(OrderFlowTabs.ExtendedInsurance));
  };
  return (
    <Box align='center'>
      <StepFormWrapper $summary>
        <h1>{t('your-request')}:</h1>
        <OrderSummaryCalculation />
        <RequiredCheckWrapper>
          <CheckBox
            label={''}
            checked={privacyCheck}
            onChange={event => {
              const newCheck = event.target.checked;
              setPrivacyCheck(newCheck);
              dispatch(setLegalConsentTwo({ legalConsentTwo: newCheck }));
            }}
          />
          <ConditionsAndProduct />
        </RequiredCheckWrapper>

        {legalConsentTwo &&
          Environment !== 'staging' &&
          Environment !== 'qa' && (
            <ReCAPTCHA
              style={{ alignSelf: 'center' }}
              //@ts-ignore
              sitekey={window.OPSEEKER_CONFIG.RECAPTCHA_SITE_KEY}
              onChange={() => setRecaptcha(true)}
            />
          )}
        <NextBackButtons
          next={{
            f: handleContinue,
            label: 'NEXT',
            disabled: !privacyCheck
          }}
          back={{ f: handleBack, label: 'BACK' }}
        />
      </StepFormWrapper>
    </Box>
  );
};

export default OrderSummary;

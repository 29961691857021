import { Link } from 'react-router-dom';
import { SessionManager } from '../../../utils/session-manager';
import { ContactBox } from '../contact-box/contact-box';
import { Button, Subheading } from '../../template/main-template/main-templateStyles';
import { StepFormWrapper } from '../form-tab-presenter/form-tabStyles';
import { useTranslation } from 'react-i18next';

export const PurchaseSuccessContainer = () => {
  const { t } = useTranslation();
  return (
    <StepFormWrapper $summary>
      <h1>{t('purchase-success-title')}</h1>
      <Subheading>{t('purchase-success-subtitle')}</Subheading>
      <ContactBox />
      <Link to='/' style={{ textAlign: 'center' }}>
        <Button
          id='PurchaseSuccess_button-back-to-home_Button'
          onClick={() => {
            SessionManager.setButtonId('PurchaseSuccess_button-back-to-home_Button');
          }}
        >
          {t('button-back-to-home')}
        </Button>
      </Link>
    </StepFormWrapper>
  );
};

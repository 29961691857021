import { useTranslation } from 'react-i18next';
import { Warning } from '../../template/main-template/main-templateStyles';

export const InputWrapper = (props: { component: any; warning: any; id: string }) => {
  const { t } = useTranslation();
  return (
    <>
      {props.component}
      {props.warning && (
        <Warning>
          {t(`input-${props.id}-warning${props.warning.length > 0 ? `-${props.warning}` : ``}`)}
        </Warning>
      )}
    </>
  );
};

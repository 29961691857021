import { useTranslation } from 'react-i18next';
import {
  Wrapper,
  ErgoBoxImage,
  ErgoBar,
  ErgoLogo,
  ErgoMainBox,
  ErgoText,
  Box,
  ErgoLeftBox,
  ErgoContent,
  RegularText,
  BoldSubtitle
} from './ergoSectionStyles';

export const ErgoSection = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <ErgoContent>
        <ErgoLeftBox>
          <ErgoBoxImage />
        </ErgoLeftBox>

        <Box>
          <ErgoBar />
          <ErgoMainBox>
            <ErgoLogo />
            <ErgoText>
              <BoldSubtitle>{t('landing.ergo.title')}</BoldSubtitle>
              <RegularText>{t('landing.ergo.info')}</RegularText>
            </ErgoText>
          </ErgoMainBox>

          <ErgoBar />
        </Box>
      </ErgoContent>
    </Wrapper>
  );
};

import { Footer } from "../../organisms/footer/footer";
import { TopBar } from "../../organisms/topbar/topbar";
import { PageContent, PageDeco, PageWrapper } from "./main-templateStyles";

export const MainTemplate = (props: any) => {
  return (
    <PageWrapper>
      <TopBar />
      <PageContent>
        <>{props.children}</>
        <PageDeco />
      </PageContent>
      <Footer />
    </PageWrapper>
  );
};

import styled from "styled-components";
import { MEDIA_URL } from "../../template/main-template/main-templateStyles";
import { device } from "../../../utils/use-device-type";

export const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  background: ${(props) => props.theme.landing.colors.lightBlue};
  color: ${(props) => props.theme.landing.colors.textNegative};
  border-radius: 5px;
  padding: 15px 30px;
  > div p {
    font-size: 0.9rem;
  }
  @media (${device.tablet}) {
    gap: 40px;
    flex-direction: row;
    max-width: 500px;
    margin: 0 auto;
  }
`;

export const InfoIcon = styled.div<{ fileName: string }>`
  width: 35px;
  height: 35px;
  background: url("${MEDIA_URL}/${(props) => props.fileName}") no-repeat;
  background-size: contain;
  @media (${device.tablet}) {
    width: 65px;
    height: 65px;
  }
`;

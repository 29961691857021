import * as React from "react";
import { useParams } from "react-router-dom";
import { PurchaseErrorContainer } from "../../molecules/purchase-results/purchase-error-container";
import { PurchaseSuccessContainer } from "../../molecules/purchase-results/purchase-success-container";

export const PurchaseResultContainer = () => {
  const { state } = useParams();
  if (state === "error") {
    return <PurchaseErrorContainer />;
  } else if (state === "success") {
    return <PurchaseSuccessContainer />;
  }
  return <React.Fragment />;
};

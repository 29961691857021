import { Box } from 'grommet';
import { useEffect, useState } from 'react';
import cross from '../../../assets/cross.svg';
import discount from '../../../assets/discount.svg';
import {
  discountCodeValidation,
  resetDiscountCodeValidation
} from '../../../redux/api/OrderApi';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  OrderFlow,
  setDiscount,
  setProducts
} from '../../../redux/orderFlow/orderFlowSlice';
import {
  HOLIDAYS_PACKAGE_PRICE,
  SPORTS_PACKAGE_PRICE,
  getBasePrice,
  getDiscountPrice,
  getFlow2Price,
  getMonthsNumber,
  getNetPremium,
  getPriceTag,
  setProductEnumAsString,
  getSportsAmount,
  getHolidaysAmount
} from '../../../utils/prices-calculator';
import { SessionManager } from '../../../utils/session-manager';
import { useTranslation } from 'react-i18next';

import { TextInput } from '../../atoms/text-input/text-input';
import {
  TablePrices,
  DiscountContainer,
  DiscountButton,
  PriceContainer,
  Separationbar,
  DiscountBox,
  DiscountIcon,
  CrossIcon
} from './order-summary-styles';
import Tooltip from '../../atoms/tooltip/tooltip';

const OrderSummaryCalculation = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const orderDetails = useAppSelector(state => state.orderFlow);
  const currentFlow = useAppSelector(state => state.orderFlow.flow);
  const discountCodeValidationApi = useAppSelector(
    state => state.orderApi.discountValidation
  );
  const products = setProductEnumAsString(orderDetails);
  const months =
    currentFlow === OrderFlow.Flow1
      ? getMonthsNumber(orderDetails.returnDate, orderDetails.departureDate)
      : null;
  const [priceTag, setPriceTag] = useState<any>(undefined);
  const [discountCode, setDiscountCode] = useState<string>('');

  const discountRate = useAppSelector(
    state => state.orderApi.discountValidation.response.discountRate
  );
  const [discountCodeError, setDiscountCodeError] = useState(false);
  const handleValidationDiscountCode = async () => {
    discountCode
      ? dispatch(discountCodeValidation(discountCode))
      : setDiscountCodeError(true);
  };
  useEffect(() => {
    const { loading, response } = discountCodeValidationApi;
    const isApiCallSucceeded = loading === 'succeeded';
    const isDiscountValid = response && response.discount;

    if (isApiCallSucceeded) {
      dispatch(resetDiscountCodeValidation());

      if (isDiscountValid) {
        dispatch(
          setDiscount({
            discountCode: discountCode,
            discount: response.discount
          })
        );
      } else if (discountCode && discountCode.trim() !== '') {
        setDiscountCodeError(true);
      }
    }
  }, [
    discountCodeValidationApi.loading,
    discountCodeValidationApi.response,
    discountCode
  ]);
  useEffect(() => {
    products && dispatch(setProducts({ products: products }));
  }, [products]);
  const fetchPriceTag = async () => {
    if (orderDetails.products) {
      const response = await getPriceTag(orderDetails);
      setPriceTag(response);
    }
  };
  const fetchFlow2PriceTag = async () => {
    if (orderDetails.products) {
      const response = await getFlow2Price(orderDetails);
      setPriceTag(response);
    }
  };
  useEffect(() => {
    if (currentFlow === OrderFlow.Flow1) {
      fetchPriceTag();
    } else {
      fetchFlow2PriceTag();
    }
  }, [orderDetails]);

  const deleteDiscountCode = () => {
    setDiscountCode('');
    dispatch(
      setDiscount({
        discountCode: '',
        discount: false
      })
    );
  };
  return (
    <Box>
      {currentFlow === OrderFlow.Flow1 && (
        <TablePrices>
          <p>{t('summary-tab-1')}</p>
          <div>
            <p>
              {orderDetails.healthInsurance
                ? t('summary-tab-price-25')
                : t('summary-tab-price-36')}
            </p>
            <p>
              ({months}
              {months === 1
                ? t('summary-tab-2').slice(0, -1)
                : t('summary-tab-2')}
              )
            </p>
          </div>
        </TablePrices>
      )}
      {currentFlow === OrderFlow.Flow1 && (
        <>
          {orderDetails.discount === false ? (
            <DiscountContainer>
              <TextInput
                field={{ id: 'discountCode', type: 'string', mandatory: true }}
                onChange={setDiscountCode}
                value={discountCode}
                warning={discountCodeError}
              />
              <DiscountButton
                id='OrderSummaryCalculation_APPLY_Button'
                onClick={() => {
                  SessionManager.setButtonId(
                    'OrderSummaryCalculation_APPLY_Button'
                  );
                  handleValidationDiscountCode();
                }}
              >
                {t('APPLY')}
              </DiscountButton>
            </DiscountContainer>
          ) : (
            <Tooltip
              isDiscount={true}
              tooltipText={t('summary-tooltip-discount-text')}
            >
              <DiscountBox>
                <DiscountIcon src={discount} alt='discount' />
                <p>{discountCode}</p>
                <CrossIcon
                  src={cross}
                  alt='cross'
                  onClick={() => deleteDiscountCode()}
                />
              </DiscountBox>
            </Tooltip>
          )}
        </>
      )}

      {products !== '0' && (
        <PriceContainer>
          {orderDetails.sportsPackage && (
            <TablePrices>
              <p>{t('summary-tab-sports')}</p>
              <p>{SPORTS_PACKAGE_PRICE}€</p>
            </TablePrices>
          )}
          {orderDetails.holidayPackage && (
            <TablePrices>
              <p>{t('summary-tab-holiday')}</p>
              <p>{HOLIDAYS_PACKAGE_PRICE}€</p>
            </TablePrices>
          )}
          <Separationbar />
        </PriceContainer>
      )}
      <TablePrices>
        <p>{t('summary-tab-total')}</p>
        {currentFlow === OrderFlow.Flow1 && (
          <>
            {orderDetails.discount === false ? (
              <p>{priceTag?.amount.toFixed(2)}€</p>
            ) : (
              discountRate && (
                <p>
                  {(
                    priceTag?.amount -
                    getDiscountPrice(orderDetails, discountRate)
                  ).toFixed(2)}
                  €
                </p>
              )
            )}
          </>
        )}
        {currentFlow === OrderFlow.Flow2 && <p>{priceTag?.amount}€</p>}
      </TablePrices>
    </Box>
  );
};

export default OrderSummaryCalculation;

import { InfoBox } from '../../molecules/info-box/info-box';
import { Attributes, WhyCollage, ServicesWrapper } from './ServicesStyles';
import { attributes } from '../../../models/services';
import { useTranslation } from 'react-i18next';

export const Services = () => {
  const { t } = useTranslation();

  return (
    <ServicesWrapper>
      <WhyCollage />
      <Attributes>{attributes.map((c, i) => renderAttributes(c, i, t))}</Attributes>
    </ServicesWrapper>
  );
};

export const renderAttributes = (c: any, i: number, t: any) => {
  return (
    <InfoBox sequence={i + 1} key={`box${i + 1}`}>
      {t(`landing.services.${c.content}`)
        .split(';')
        .map((p: any, j: number) => (
          <p key={`par${j}`}>{p}</p>
        ))}
    </InfoBox>
  );
};

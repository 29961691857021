import '@coreui/coreui/dist/css/coreui.min.css';
import { InputWrapper } from '../input-wrapper/input-wrapper';
import { FormField } from '../../../models/form';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
export const PrefixPhoneInput = (props: {
  field: FormField;
  value: string;
  onChange: (val: string) => void;
  warning: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <InputWrapper
      component={
        <StyledPhoneInput
          placeholder={t('input-phoneNumber')}
          value={props.value}
          onChange={props.onChange}
          defaultCountry='ES'
          maxLength={20}
        />
      }
      warning={props.warning}
      id={props.field.id}
    />
  );
};

const StyledPhoneInput = styled(PhoneInput)`
  --PhoneInputCountrySelectArrow-color: white;
  --PhoneInputCountryFlag-borderColor: white;
`;

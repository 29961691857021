import React, { useState } from 'react';
import { characteristics } from '../../../models/services';
import {
  Wrapper,
  Characteristics,
  Characteristic,
  Title,
  Icon,
  Description,
  CloseButton
} from './CoveragesStyles';
import { useTranslation } from 'react-i18next';

export const Coverages = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <h2>{t('landing.coverages.title')}</h2>
      <Characteristics>
        {characteristics[0].EN.map((c, i) => (
          <RenderCharacteristics c={c} i={i} t={t} />
        ))}
      </Characteristics>
    </Wrapper>
  );
};

const RenderCharacteristics = ({ c, i, t }: { c: any; i: any; t: any }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Characteristic
      index={i}
      key={i}
      title={t(`landing.coverages.${c.text}`)}
      isExpanded={isExpanded}
      onClick={handleClick}
    >
      {!isExpanded && <Icon fileName={c.icon} />}
      <Title>{t(`landing.coverages.${c.text}`)}</Title>
      {isExpanded && (
        <Description>
          {t(`landing.coverages.description.${c.text}`)}
        </Description>
      )}
      {isExpanded && <CloseButton onClick={handleClick} />}
    </Characteristic>
  );
};

import { OrderDetails, Products } from '../models/order-details';
import { OrderFlowState, OrderFlow } from '../redux/orderFlow/orderFlowSlice';

const BASE_PRICE_WITH_INSURANCE = 25;
const BASE_PRICE_WITHOUT_INSURANCE = 36;
export const SPORTS_PACKAGE_PRICE = 85;
export const HOLIDAYS_PACKAGE_PRICE = 80;
//@ts-ignore
const CLEA = window.OPSEEKER_CONFIG.CLEA;
//@ts-ignore
const CONSORTIUM = window.OPSEEKER_CONFIG.CONSORTIUM;
export const getBasePrice = (Order: OrderDetails) => {
  const monthsNumber = getMonthsNumber(Order.returnDate, Order.departureDate);

  return Order.healthInsurance
    ? monthsNumber * BASE_PRICE_WITH_INSURANCE
    : monthsNumber * BASE_PRICE_WITHOUT_INSURANCE;
};
export const getNetPremium = (basePrice: number) => {
    const netPremium = (basePrice - CONSORTIUM) / CLEA;
    return Number(netPremium);
}
export const getDiscountPrice = (Order: OrderDetails, discountRate: number) => {
  const netPremium = getNetPremium(getBasePrice(Order));
  const dis = (netPremium * discountRate);
  return Number(dis);
};

export const getSportsAmount = (Order: OrderDetails) =>
  Order.sportsPackage ? SPORTS_PACKAGE_PRICE : 0;

export const getHolidaysAmount = (Order: OrderDetails) =>
  Order.holidayPackage ? HOLIDAYS_PACKAGE_PRICE : 0;
export interface PriceTag {
  amount: number;
  currency: string;
  discountCode: string | undefined;
}
export const getPriceTag = async (Order: OrderDetails): Promise<PriceTag> => {
  const validProducts = ['0', '1', '2', '3', '4', '5', '6'];
  const checkLabel = Order.products && validProducts.includes(Order.products);

  if (!checkLabel) {
    return Promise.reject('Invalid product');
  }

  const basePrice = getBasePrice(Order);
  const sportsAmount = getSportsAmount(Order);
  const holidaysAmount = getHolidaysAmount(Order);
  const price = basePrice + sportsAmount + holidaysAmount;
  return Promise.resolve({
    amount: price,
    currency: 'EUR',
    discountCode: Order.discountCode,
  });
};

export const getFlow2Price = async (Order: OrderDetails): Promise<PriceTag> => {
  const sportsAmount = getSportsAmount(Order);
  const holidaysAmount = getHolidaysAmount(Order);
  const price = sportsAmount + holidaysAmount;
  return Promise.resolve({
    amount: price,
    currency: 'EUR',
    discountCode: Order.discountCode,
  });
};

export const getMonthsNumber = (returnDate: string, departureDate: string) => {
  let departureD = departureDate.split('-');
  let returnD = returnDate.split('-');
  let monthsNumber = (parseInt(returnD[0]) - parseInt(departureD[0])) * 12;
  monthsNumber -= parseInt(departureD[1]);
  monthsNumber += parseInt(returnD[1]);
  if (
    departureD[2].localeCompare(returnD[2]) == 0 ||
    departureD[2].localeCompare(returnD[2]) == -1
  ) {
    monthsNumber += 1;
  }
  return monthsNumber <= 0 ? 0 : monthsNumber;
};

export function setProductEnumAsString(order: OrderFlowState): string {
  let selectedProduct = Products.Main;

  if (order.flow === OrderFlow.Flow1) {
    if (order.sportsPackage && order.holidayPackage) {
      selectedProduct = Products.MainSportsHolidays;
    } else if (order.sportsPackage) {
      selectedProduct = Products.MainSports;
    } else if (order.holidayPackage) {
      selectedProduct = Products.MainHolidays;
    }
  } else if (order.flow === OrderFlow.Flow2) {
    if (order.sportsPackage && order.holidayPackage) {
      selectedProduct = Products.SportsHolidays;
    } else if (order.sportsPackage) {
      selectedProduct = Products.Sports;
    } else if (order.holidayPackage) {
      selectedProduct = Products.Holidays;
    }
  }

  return selectedProduct.toString();
}

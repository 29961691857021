import styled from "styled-components";
import { MEDIA_URL } from "../../template/main-template/main-templateStyles";

export const ServicesWrapper= styled.div`
display:flex;
justify-content: center;
align-content: center;
align-items: center;
flex-wrap:wrap;
padding-bottom:30px;
margin: 0 auto;
@media (min-width: 768px){max-width:700px;}
@media (min-width: 991px) {flex-wrap:nowrap;margin:20px auto 70px auto;max-width:900px;}
`

export const Attributes = styled.div`
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  position:relative;
 
  @media (min-width: 991px) {
    justify-content: center;
    flex-wrap: nowrap;
    gap: 20px;
  }
`;

export const WhyCollage = styled.div`
  width:100%;
  height: 140px;
  background: url("${MEDIA_URL}/why.png") no-repeat center;
  background-size: contain;
  margin: 0 auto;
  margin-top:-30px;
  @media (min-width: 300px) {width:300px;}
  @media (min-width: 991px) {
    margin-top:0;
    width: 720px;
    height: 219px;
  }
`;

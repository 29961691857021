import { Link } from 'react-router-dom';
import { RequiredLabel } from '../../atoms/check-box/check-box';
import { useTranslation } from 'react-i18next';

export const PrivacyAndTerms = () => {
  const { t } = useTranslation();
  return (
    <span>
      {t('landing.contact.privacy-and-terms-text-1')}
      <Link
        to='/privacy-policy'
        target='_blank'
        rel='noopener noreferrer'
        style={{ display: 'inlineBlock', margin: '0 2px' }}
      >
        {t('landing.contact.privacy-and-terms-link-1')}
      </Link>
      {t('landing.contact.privacy-and-terms-text-2')}
      <Link
        to='/legal-notice'
        target='_blank'
        rel='noopener noreferrer'
        style={{ display: 'inlineBlock', margin: '0 2px' }}
      >
        {t('landing.contact.privacy-and-terms-link-2')}
      </Link>
      <RequiredLabel> {t('landing.contact.required-label')}</RequiredLabel>
    </span>
  );
};
